//A card component that displays a jobsite's information. 

import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaCamera, FaMapMarkerAlt } from 'react-icons/fa';
import { IoLogInOutline, IoLogOutOutline } from 'react-icons/io5';

import Spinner from '../../components/Spinner';

// Dispatchable actions from the currentJobsiteSlice
import {retrievePunchData, punchIn, punchOut} from '../../features/currentJobsite/currentJobsiteSlice';
import {setJobsite, setDistanceAway, setNextPunchType} from '../../features/currentJobsite/currentJobsiteSlice';

//Utility function to calculate the distance between two points
//  Haversine formula 
// Distance returned in km
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const toRad = (value) => (value * Math.PI) / 180;
  const R = 6371; // Radius of the Earth in km
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};



const WorkerJobsite = ({ jobSite }) => {
  const [isWithinRadius, setIsWithinRadius] = useState(false);
  const [distance, setDistance] = useState(null);

  const { workerLocation } = useSelector((state) => state.workerAuth);
  const { clockedInData, clockedOutData, jobsite: currentJobsite } = useSelector(
    (state) => state.currentJobsite
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Calculate the distance between the worker and the jobsite
  useEffect(() => {
    const calculatedDistance = calculateDistance(
      workerLocation.latitude,
      workerLocation.longitude,
      jobSite.latitude,
      jobSite.longitude
    );

    setDistance(calculatedDistance);
    setIsWithinRadius(calculatedDistance <= jobSite.radius / 1000); // Convert radius to km
  }, [workerLocation, jobSite]);

  // If the worker is within the radius of the jobsite, set the jobsite and retrieve punch data
  useEffect(() => {
    if (isWithinRadius) {
      dispatch(setJobsite(jobSite));
      dispatch(setDistanceAway(distance));
      dispatch(retrievePunchData(jobSite.id));
    }
  }, [isWithinRadius, jobSite, dispatch]);

  // Executes the routing when button is clicked
  const handlePunchBtn = () => {
    navigate(`/punch/${jobSite.id}`);
  };

  const handleIconClick = (punchType) => {
    dispatch(setNextPunchType(punchType));
    handlePunchBtn();
  };

  const convertToLocalTime = (utcTime) => {
    if (!utcTime) {
      return "No";
    }
    const date = new Date(utcTime);
    return date.toLocaleTimeString(); // Converts to local time string, only time portion
  };

  return (
    <>
      
      {/* Title */}
      <div className="mb-0">
        <h1 className="text-2xl font-kanit font-normal text-left">
          {jobSite.jobsiteName}
          {distance !== null && (
            <span className="text-sm text-gray-500">
              {distance * 0.621371 < 1
                ? ` (${(distance * 5280).toFixed(0)} ft)`
                : ` (${(distance * 0.621371).toFixed(2)} mi)`}
            </span>
          )}
        </h1>
      </div>

      {/* Image */}
      <div className="mt-1 mb-6">
        <img
          src={jobSite.s3URL}
          alt={jobSite.jobsiteName}
          className="w-full h-48 object-cover rounded-lg mb-4"
        />
      </div>

      {/* Address */}
      <div className="flex justify-center mb-2">
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(jobSite.address)}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-600 text-xs flex items-center underline decoration-dotted"
        >
          <FaMapMarkerAlt className="text-lg text-red-500 mr-1" />
          {jobSite.address}
        </a>
      </div>

      {/* Notes */}
      {jobSite.siteNotes ? (
        <div className="rounded-lg max-h-32 overflow-y-auto p-2 bg-gray-100 border border-gray-200">
          {Object.entries(jobSite.siteNotes).map(([key, value], index) => (
            <div key={index} className="mb-2">
              <p className="text-gray-700 text-sm font-kanit font-medium text-left">• {key}:</p>
              <p className="text-gray-600 font-kanit font-light text-sm text-left">{value}</p>
            </div>
          ))}
        </div>
      ) : (
        <div className="rounded-lg">
          <p className="text-gray-700 text-xs">No Notes</p>
        </div>
      )}

      {/* Icons with Clickable Functionality */}
      {currentJobsite?.id === jobSite.id && (
        <div className="flex justify-around mt-4">
          {/* Clock In Icon */}
          <button
            className={`text-gray-700 text-sm ${
              !clockedInData && isWithinRadius ? "animate-pulse" : ""
            }`}
            onClick={() => handleIconClick("clockIn")}
            disabled={!isWithinRadius || clockedInData}
          >
            <IoLogInOutline className="mx-auto mb-2 fill-teal stroke-2" size={24} />
            <p>{clockedInData ? convertToLocalTime(clockedInData) : <span className="text-teal">Not Clocked In</span>}</p>
          </button>

          {/* Clock Out Icon */}
          <button
            className={`text-gray-700 text-sm ${
              clockedInData && !clockedOutData && isWithinRadius ? "animate-pulse" : ""
            }`}
            onClick={() => handleIconClick("clockOut")}
            disabled={!isWithinRadius || !clockedInData || clockedOutData}
          >
           <IoLogOutOutline className={`mx-auto mb-2 stroke-2 ${clockedOutData ? 'fill-red-500' : 'fill-none text-red-500'}`} size={24} />
            <p>{clockedOutData ? convertToLocalTime(clockedOutData) : <span className="text-red-500">Not Clocked Out</span>}</p>
          </button>
        </div>
      )}

    
    </>
  );
};

export default WorkerJobsite;