import { Link } from 'react-router-dom';
import { FaUsers, FaCog, FaSignOutAlt } from 'react-icons/fa';
import { MdHomeWork } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify'
import { getSubscription, subscriptionSlice } from '../features/subscription/subscriptionSlice'
import { logout, reset } from '../features/auth/authSlice'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { BsBuildingGear } from "react-icons/bs";
function Homedash() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  // const { subInfo } = useSelector((state) => state.subscription);


  useEffect(() => {
    dispatch(getSubscription());
  }, [dispatch]);


  // logout user
  const onLogout = () => {
    dispatch(subscriptionSlice.actions.reset()); //reset the subscription state
    dispatch(logout())
    dispatch(reset())
    navigate("/login")
  }

  return (
    <>
      <div>
        <h1 className="text-3xl text-center font-black uppercase mt-4">Welcome {user && user.company}</h1>
      </div>

      {/* Mobile Logout Button - Only visible on small screens */}
      <div className="md:hidden flex justify-center mt-4">
        <button
          onClick={onLogout}
          className="flex items-center px-4 py-2 bg-red-600 text-white rounded-lg shadow-md hover:bg-red-700 transition-colors"
        >
          <FaSignOutAlt className="mr-2" /> Logout
        </button>
      </div>

      {/* App Pages */}
      <div className="flex flex-col items-center justify-center md:flex-row md:flex-wrap gap-4 p-2 min-h-screen mt-[-20px]">

        <div className="p-4 my-4 w-full md:w-80 relative">
            <Link to="/jobsites" className="flex items-center justify-center group text-xl border border-white bg-gray-100 hover:bg-gray-200 transition-all duration-200 transform hover:scale-105 rounded-lg shadow-md p-6">
            <MdHomeWork className='text-3xl mr-2 text-slate-600'/>JobSites   
              <span className="hidden lg:block opacity-0 bg-indigo-700
               text-white text-md rounded-md py-1 px-2 
               absolute top-0 left-1/2 transform -translate-x-1/2 
               lg:group-hover:opacity-100 lg:group-hover:translate-y-20 
               transition-all duration-200">View and manage all your jobsites
              </span>
          </Link>
        </div>
        
        <div className="p-4 my-4 w-full md:w-80 relative">
           <Link to="/workers" className="flex items-center justify-center group text-xl border border-white bg-gray-100 hover:bg-gray-200 transition-all duration-200 transform hover:scale-105 rounded-lg shadow-md p-6">
          <FaUsers className="text-3xl mr-2 text-slate-600" /> Workers
            <span className="hidden lg:block opacity-0 bg-indigo-700
               text-white text-md rounded-md py-1 px-2 
               absolute top-0 left-1/2 transform -translate-x-1/2 
               lg:group-hover:opacity-100 lg:group-hover:translate-y-20 
               transition-all duration-200">View and manage workers
              </span>
          </Link>
        </div>

        <div className="p-4 my-4 w-full md:w-80 relative">
          <Link to="/userInfo" className="flex items-center justify-center group text-xl border border-white bg-gray-100 hover:bg-gray-200 transition-all duration-200 transform hover:scale-105 rounded-lg shadow-md p-6">
            <FaCog className="text-3xl mr-2 text-slate-600" /> Settings
            <span className="hidden lg:block opacity-0 bg-indigo-700 text-white text-md rounded-md
             py-1 px-2 absolute top-0 left-1/2 transform -translate-x-1/2 
             lg:group-hover:opacity-100 lg:group-hover:translate-y-20 transition-all 
             duration-200">Modify subscription settings.</span>
          </Link>
        </div>

      </div>
    </>
  );
}

export default Homedash;