//This page will implement the facial liveness UI from AWS Amplify

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Amplify } from 'aws-amplify';
import { ThemeProvider, Loader } from '@aws-amplify/ui-react';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import '@aws-amplify/ui-react/styles.css';
import awsexports from '../aws-exports';
import { punchIn, punchOut , clearPunchState} from '../features/currentJobsite/currentJobsiteSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

Amplify.configure(awsexports);

// onLivenessResult is a function that will be passed from the parent component.
// It takes the result of the liveness test and the session id as arguments.
// It is a callback function, a parent component must implement it to get the result of the liveness test.
function FacialLiveness({ jobsiteId, workerLat, workerLong, nextPunchType }) {
  
  const [isLive, setIsLive] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sessionId, setSessionId] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isError, message, isSuccess } = useSelector((state) => state.currentJobsite);

  useEffect(() => {
    // Call backend to create a liveness session, assign the session id to the state sessionId
    const fetchCreateLiveness = async () => {
      const response = await fetch(process.env.REACT_APP_LIVENESS_CreateSession).catch((error) => {
        toast.error('Error creating liveness session: ' + error.message);
        console.error('Error:', error);
      });

      const data = await response.json();
      setSessionId(data.livenessSessionId);
      setLoading(false);
    };

    fetchCreateLiveness();
  }, []);

  // Get the analysis data from the backend.
  const handleAnalysisComplete = async () => {
    // Send a POST request to the backend to get the analysis results for the liveness session.
    // The sessionId is sent in the request body to identify the session.
    // The backend will return the analysis results, which include whether the liveness check was successful.
    const response = await fetch(process.env.REACT_APP_LIVENESS_GET_SESSION_RESULTS_API, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ sessionId: sessionId }),
    });

    // Note: The isLive flag is not returned from the GetFaceLivenessSession API
    // This should be returned from your backend based on the score that you
    // get in response. Based on the return value of your API you can determine what to render next.
    // Any next steps from an authorization perspective should happen in your backend and you should not rely
    // on this value for any auth related decisions.
    const data = await response.json();

    // data.isLive holds true or false
    if (data.isLive === true) {
      setIsLive(true);

      // Dispatch the appropriate action based on nextPunchType
      const punchData = {
        jobsiteId: jobsiteId,
        workerLat: workerLat,
        workerLong: workerLong,
        sessionId: sessionId,
      };

      try {

        // Need to fix this, if backend returns error, we should toast the error message 
        if (nextPunchType === 'clockOut') {
          await dispatch(punchOut(punchData));
          if (isError) {
              toast.error(message); // Show error message from Redux
          } else if (isSuccess) {
              toast.success(message);
              navigate('/workerDash');
              dispatch(clearPunchState()); // Clear state
          }
      } else {
          await dispatch(punchIn(punchData));
          if (isError) {
              toast.error(message); // Show error message from Redux
          } else if (isSuccess) {
              toast.success(message);
              navigate('/workerDash');
              dispatch(clearPunchState()); // Clear state
          }
      }

      } catch (error) {
        console.error('Error dispatching punch action:', error);
        toast.error('An error occurred while processing your punch. Please try again.');
      }
    } else {
      setIsLive(false);
    }
  };

  return (
    <>
      <ThemeProvider>
        {/* While getting the session id, render the loading bar */}
        {loading ? (
          <Loader />
        ) : (
          <>
            {isLive === null ? (
              <>
                <p>Come closer</p>
                <FaceLivenessDetector
                  sessionId={sessionId}
                  region="us-east-1"
                  onAnalysisComplete={handleAnalysisComplete}
                  onError={(error) => {
                    console.error(error.stack);
                    toast.error('Liveness detection error: ' + error.message);
                  }}
                  disableStartScreen={true}
                />
              </>
            ) : isLive ? (
              <>

                <p>Liveness Test Passed, {nextPunchType} </p>
                <button
                  onClick={() => navigate('/workerDash')} // Navigate to workerDash
                  className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700"
                >
                  Go to Dashboard
                </button>

              </>

              

            ) : (
              <>
                <p>Liveness Test Failed</p>
                <button
                  onClick={() => setIsLive(null)}
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                >
                  Try Again
                </button>
              </>
            )}
          </>
        )}
      </ThemeProvider>
    </>
  );
}

export default FacialLiveness;