import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { FaCalendarAlt, FaBars, FaSignOutAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import getJobsites from '../../actions/workerUserActions/fetchJobSites';
import WorkerJobsite from '../../components/workerUser_Components/WorkerJobsite';
import { setWorkerLocation } from '../../features/workerAuth/workerAuthSlice';
import Spinner from '../../components/Spinner';
import {logoutWorker, resetWorkerUser} from '../../features/workerAuth/workerAuthSlice'
// Function to calculate distance between two coordinates
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const toRad = (value) => (value * Math.PI) / 180;
  const R = 6371; // Radius of the Earth in km
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon1 - lon2);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLon / 2) +
    Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in km
};

function WorkerDash() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  //handle logout click
  const handleLogoutClick = () => {
    dispatch(logoutWorker())     //removes the workerUser data from local storage
    dispatch(resetWorkerUser())  //resents the redux state to initial state for slice workerAuth
    navigate("/workerLogin")
};
  
  // State variables
  const [jobSites, setJobSites] = useState([]);
  const [sortedJobSites, setSortedJobSites] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [workerLocation, setWorkerLocationState] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Redux store states
  const { workerUser } = useSelector((state) => state.workerAuth);
  const { webToken, profilePictureUrl } = workerUser;

  // Handle navigation to calendar
  const handleHistoryClick = () => {
    navigate(`/calendar`);
  };

  // Check onboarding status
  useEffect(() => {
    const { onboarded } = workerUser;
    if (onboarded === false) {
      toast.info('Complete your onboarding');
      navigate('/workerOnboarding');
    }
  }, [workerUser, navigate]);

  // Fetch job sites and worker location
  useEffect(() => {
    if (workerUser.onboarded === false) {
      return;
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          dispatch(setWorkerLocation(location));
          setWorkerLocationState(location);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
      toast.error('Failed to connect to location services');
    }

    // Fetch jobsites
    const fetchData = async () => {
      try {
        const jobSitesData = await getJobsites(webToken);
        setJobSites(jobSitesData);
      } catch (error) {
        setError('Error fetching job sites');
        console.error('Error fetching job sites:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [webToken, workerUser.onboarded, dispatch]);

  // Sort job sites by distance
  useEffect(() => {
    if (workerLocation && jobSites.length > 0) {
      const sorted = jobSites
        .map((jobSite) => ({
          ...jobSite,
          distanceAway: calculateDistance(
            workerLocation.latitude,
            workerLocation.longitude,
            jobSite.latitude,
            jobSite.longitude
          ),
        }))
        .sort((a, b) => a.distanceAway - b.distanceAway);

      setSortedJobSites(sorted);
    }
  }, [workerLocation, jobSites]);

  // Render loading spinner
  if (isLoading) {
    return <Spinner />;
  }

  // Render error message
  if (error) {
    return <div className="p-4 text-center text-red-500">{error}</div>;
  }

  return (
    <div className="flex flex-col lg:flex-row">
      {/* Sidebar */}
      <div className={`lg:w-1/4 p-2 bg-slate-100 rounded-lg flex flex-col items-center ${sidebarOpen ? 'block' : 'hidden'} lg:flex`}>
        <button
          className="p-2 bg-white text-black font-sans font-medium rounded-lg flex items-center hover:bg-slate-600 hover:text-white transition duration-300 lg:hidden hidden"
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          <FaBars className="mr-2" />
          {workerUser.workerName} {workerUser.workerLastName}
        </button>
        {(sidebarOpen || window.innerWidth >= 1024) && (
          <>
            <img
              src={profilePictureUrl}
              alt={`${workerUser.workerName} ${workerUser.workerLastName}`}
              className="w-24 h-24 rounded-full mx-auto"
            />
            <p className="text-xl mt-2 text-center">
              <span className="text-gray-700 text-3xl">
                <p>
                {workerUser.workerName} {workerUser.workerLastName}
                  </p>
              </span>
            </p>
            <button
              className="mt-4 p-2 font-sans text-lg flex items-center hover:bg-slate-300 hover:text-black"
              onClick={handleHistoryClick}
            >
              <FaCalendarAlt className="mr-2" />
              Work History
            </button>
            <button
                className="mt-4 p-2 font-sans text-lg flex items-center bg-red-500 text-white rounded-md lg:hidden"
                onClick={handleLogoutClick}
            >
                <FaSignOutAlt className="mr-2" />
                Log Out
            </button>
           
          </>
        )}
      </div>

      {/* Hamburger button for small screens */}
      <div className="lg:hidden p-4">
        <button
          className="p-2 bg-black text-white font-sans font-medium rounded-lg flex items-center hover:bg-slate-600 hover:text-white transition duration-300"
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          <FaBars className="mr-2" />
          {workerUser.workerName} {workerUser.workerLastName}
        </button>
      </div>

      {/* Job sites container */}
      <div className="container mx-auto px-4 lg:px-0 lg:w-full lg:h-screen lg:overflow-y-auto">
        {sortedJobSites.length !== 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 lg:gap-8 gap-4">
            {sortedJobSites.map((jobSite) => (
              <div key={jobSite.id} className="p-4 bg-white shadow-md rounded-lg">
                <WorkerJobsite jobSite={jobSite} />
              </div>
            ))}
          </div>
        ) : (
          <div className="p-4 text-center">
            <h2 className="text-2xl text-gray-500">
              Employer doesn't have any jobsites
            </h2>
          </div>
        )}
      </div>
    </div>
  );
}

export default WorkerDash;