import {
    FaMobileAlt,        // No Hardware Required
    FaUserCircle,        // Accurate Facial Recognition
    FaMapPin,            // Precise Geolocation
    FaArrowsAlt,         // Seamless Scalability
    FaMicrochip,         // Effortless Integration
    FaLightbulb           // Future-Ready Tech
  } from 'react-icons/fa';
// Updated feature list with corresponding icons
const features = [
    {
        name: 'No Hardware Required',
        description: 'Use the devices your team already has—no need for additional hardware investments.',
        icon: FaMobileAlt,
    },
    {
        name: 'Accurate Facial Recognition',
        description: 'Advanced facial recognition ensures accurate, convenient, and fraud-free attendance tracking.',
        icon: FaUserCircle,
    },
    {
        name: 'Precise Geolocation',
        description: 'Track attendance with precise geolocation, ensuring workers are exactly where they need to be.',
        icon: FaMapPin,
    },
    {
        name: 'Seamless Scalability',
        description: 'Easily scale across teams, projects, and locations without complex setup or hidden costs.',
        icon: FaArrowsAlt,
    },
    {
        name: 'Effortless Integration',
        description: 'Seamlessly integrates with TimeStat and other tools for a complete workforce management solution.',
        icon: FaMicrochip,
    },
    {
        name: 'Future-Ready Tech',
        description: 'Stay ahead with cutting-edge technology designed to evolve and adapt to your business needs.',
        icon: FaLightbulb,
    },
];

export default function FacialRecognitionSolutionEnhanced() {
    const accentColor = 'cyan'; // Choose your accent: 'blue', 'teal', 'indigo', 'purple', etc.

    return (
        // Slightly adjusted gradient, maybe vertical feels cleaner
        <div className="bg-gradient-to-b from-gray-900 to-black text-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-y-16 gap-x-8 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    {/* Left Column: Text Content & Features */}
                    <div className="lg:pt-4">
                        {/* Kicker/Subtitle using accent color */}
                        <p className={`text-base font-semibold leading-7 text-${accentColor}-400`}>The Future of Attendance</p>
                        <h2 className={`mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl`}>
                            Effortless, Accurate Tracking
                        </h2>
                        <p className="mt-6 text-lg leading-8 text-gray-300">
                            Leverage the power of AI-driven computer vision directly on your team's existing smartphones. Eliminate hardware costs and streamline attendance with unparalleled accuracy.
                        </p>

                        {/* Feature Grid with Icons */}
                        <dl className="mt-10 grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:mt-16">
                            {features.map((feature) => (
                                // Group class allows parent hover state to affect children
                                <div key={feature.name} className="relative pl-16 group">
                                    <dt className="text-base font-semibold leading-7 text-gray-100 group-hover:text-white transition-colors">
                                        {/* Icon Container with Accent Background */}
                                        <div className={`absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-${accentColor}-500 group-hover:bg-${accentColor}-400 transition-colors`}>
                                            <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </div>
                                        {feature.name}
                                    </dt>
                                    <dd className="mt-2 text-sm leading-6 text-gray-400 group-hover:text-gray-300 transition-colors">
                                        {feature.description}
                                    </dd>
                                    {/* Optional: Subtle border highlight on hover */}
                                     <div className={`absolute -inset-x-4 -inset-y-2 left-0 h-full w-0.5 bg-${accentColor}-400 opacity-0 group-hover:opacity-100 transition-opacity duration-300`} aria-hidden="true"></div>
                                </div>
                            ))}
                        </dl>
                    </div>

                    {/* Right Column: Image */}
                    <div className="flex items-center justify-center lg:justify-end lg:pl-8">
                         {/* Added subtle glow effect with shadow using the accent color */}
                        <img
                            src="/facialRecog-nobg.png" // Make sure this path is correct
                            alt="Facial Recognition Mobile App Interface"
                            className={`rounded-xl shadow-2xl shadow-${accentColor}-500/20 hover:shadow-${accentColor}-400/40 transition-all duration-500 ease-out transform hover:scale-105 w-[24rem] max-w-none sm:w-[32rem] md:-ml-4 lg:-ml-0`}
                            // Adjusted width constraints for responsiveness
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}