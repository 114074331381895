

// Shows ther user's information, including their cards, contact information, and subscription details.


import SetupForm from '../components/stripe/SetupForm';
import CardsList  from '../components/stripe/CardsList';
import SubscriptionDetails from '../components/SubscriptionDetails';
import ContactInfo from '../components/ContactInfo'
const UserInfo = () => {
  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className='sm:w-full md:max-w-3xl'>
        <div className='mt-2'>
          {/* List cards belonging to the user */}
          <CardsList />
          
          {/* Add New Card */}
            <div className='mt-4'>
              <SetupForm />

            </div>
         
        </div>
        
        <div className='mt-4'>
          <ContactInfo />
        </div>

        {/* Subscription information of the user's account */}
        <div className='mt-4'>
          <SubscriptionDetails />
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
