import React from 'react';
import { FaCheck } from 'react-icons/fa';

export default function Pricing() {
  return (
    <div className="bg-gradient-to-r from-gray-800 via-gray-900 to-black p-8 rounded-lg shadow-lg">
      <h2 className="text-5xl font-mono text-center mb-8 text-white">Pricing</h2>
      <div className="rounded-lg bg-gray-800 shadow-lg max-w-md mx-auto p-8">
        <div className="flex flex-col items-center text-gray-300 mb-2">
          <span className="text-6xl font-sans text-white">$30</span>
          <span className="text-md font-sans text-gray-400">per month</span>
        </div>
        <ul className="text-gray-400 space-y-4 mb-8">
          <li className="flex items-center text-lg">
            <FaCheck className="text-blue-500 mr-2" />
            Unlimited Workers
          </li>
          <li className="flex items-center text-lg">
            <FaCheck className="text-blue-500 mr-2" />
            Unlimited Jobsites
          </li>
          <li className="flex items-center text-lg">
            <FaCheck className="text-blue-500 mr-2" />
            3 years of cloud storage
          </li>
          <li className="flex items-center text-lg">
            <FaCheck className="text-blue-500 mr-2" />
            Worker Analytics
          </li>
          <li className="flex items-center text-lg">
            <FaCheck className="text-blue-500 mr-2" />
            Cancel Anytime, no Contracts
          </li>
        </ul>
        <button className="w-full bg-gray-400 hover:from-gray-600 hover:to-gray-500 text-white font-bold py-3 rounded-lg text-xl transition duration-300 transform hover:scale-105">
          <a href="/register" className="block text-center">Get Started Today</a>
        </button>
      </div>
    </div>
  );
}