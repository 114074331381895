import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../styles/CelendarStyles.css'; // Import the custom styles
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getAttendanceByJobsite, reset } from '../features/workHistory/workHistroySlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AttendanceByJobsite = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { jobsiteID, jobsiteName } = location.state;

    const [dateRange, setDateRange] = useState([new Date(), new Date()]);
    const [dateFrom, dateTo] = dateRange;

    // Redux states
    const { employerView_byJobsite: attendanceData, isError, message } = useSelector(state => state.workHistory);

    const onChange = (range) => {
        if (range.length === 2) {
            const [start, end] = range;
            const diffTime = Math.abs(end - start);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            if (diffDays > 6) {
                const newEnd = new Date(start);
                newEnd.setDate(start.getDate() + 6);
                setDateRange([start, newEnd]);
            } else {
                setDateRange(range);
            }
        } else {
            setDateRange(range);
        }
    };

    useEffect(() => {
        const calendarParams = {
            dateFrom: dateFrom.toISOString(),
            dateTo: dateTo.toISOString(),
            jobsiteID
        };
        dispatch(getAttendanceByJobsite(calendarParams));
    }, [dateRange, jobsiteID, dispatch]);

    useEffect(() => {
        if (isError) {
            toast.error(message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(reset());
        }
    }, [isError, message, dispatch]);

    const groupByDate = (data) => {
        return data.reduce((acc, item) => {
            const date = new Date(item.localDate).toDateString();
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(item);
            return acc;
        }, {});
    };

    const groupedData = groupByDate(attendanceData);

    return (
        <div className="flex flex-col md:flex-row">
            <ToastContainer />
            <div className="w-full md:w-1/3 p-4 min-w-[300px]">
                <h1 className="text-3xl font-bold p-4 bg-gray-800 text-white rounded-lg shadow-md mb-6">
                    {jobsiteName}
                </h1>
                
                <div className="calendar-wrapper">
                    <Calendar
                        selectRange={true}
                        onChange={onChange}
                        value={dateRange}
                    />
                    <div className="date-info">
                        <p className="mt-4">Date From: {dateFrom.toDateString()}</p>
                        <p>Date To: {dateTo.toDateString()}</p>
                    </div>
                </div>
            </div>
    
            <div className="w-full md:w-2/3 p-4">
                <h1 className="text-3xl font-bold p-4 bg-gray-800 text-white rounded-lg shadow-md mb-6">
                    Punch Record
                </h1>
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white">
                        <thead>
                            <tr>
                                {Object.keys(groupedData).map(date => (
                                    <th key={date} className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm leading-4 font-medium text-gray-700 uppercase tracking-wider">
                                        {date}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(groupedData).map(date => (
                                <td key={date} className="py-2 px-4 border-b border-gray-200">
                                    <ul>
                                        {groupedData[date].map((worker, index) => (
                                            <li key={index} className="mb-2">
                                                <div>
                                                    <img src={worker.profilePicture} alt={`${worker.name} ${worker.lastName}`} width="50" height="50" />
                                                    <p>{worker.name} {worker.lastName}</p>
                                                    <p>Role: {worker.role}</p>
                                                    <p>Phone: {worker.phone}</p>
                                                    <p>Time In: {new Date(worker.timeIn).toLocaleTimeString()}</p>
                                                    {worker.timeOut && <p>Time Out: {new Date(worker.timeOut).toLocaleTimeString()}</p>}
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </td>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default AttendanceByJobsite;