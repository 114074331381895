

//will take a s3 url as prop, and a file input with a submit button. 
import React, { useState} from "react";
import uploadImageToS3 from "../actions/uploadImageToS3";
import { toast } from "react-toastify";
import {updateImageURL} from '../actions/updateDB'
import {useSelector } from 'react-redux'


const NewSiteImageUploadForm = ({ signedURL, jobsiteID }) => {
    const [image, setImage] = useState(null);
    const { token } = useSelector((state) => state.auth.user);

    const uploadImage = async (e) => {
        e.preventDefault();

        if (!image) {
            console.log("No image selected");
            return;
        } else {
            try {
                const imageObjectUrl = await uploadImageToS3(image, signedURL);

                if (imageObjectUrl) {
                    try {
                        const updateDatabase = await updateImageURL(imageObjectUrl, jobsiteID, token);
                        toast.success('Image uploaded successfully');
                    } catch (error) {
                        console.log("Error updating the database: " + error);
                    }
                }
            } catch (error) {
                console.log("Error uploading the image: " + error);
            }
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files && files[0]) {
            setImage(files[0]);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    return (
        <div className="flex flex-col items-center justify-center p-6 m-6 bg-gradient-to-r from-slate-900 to-black rounded-lg shadow-lg">
            <h1 className="text-white text-2xl mb-4">Upload Jobsite Image</h1>
            <form onSubmit={uploadImage}>
                <div
                    className="w-full h-32 border-4 border-dashed border-gray-300 flex items-center justify-center text-gray-500 mb-4"
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                >
                    {image ? (
                        <p>{image.name}</p>
                    ) : (
                        <p>Drag and drop an image here, or click to select one</p>
                    )}
                </div>
                <input
                    type="file"
                    className="hidden"
                    onChange={(e) => setImage(e.target.files[0])}
                />
                <button
                    type="submit"
                    className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                >
                    Upload Image
                </button>
            </form>
        </div>
    );
};

export default NewSiteImageUploadForm;