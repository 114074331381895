import axios from 'axios'

const API_URL_LOGIN = '/api/worker/logIn'

// Routes for usertype:worker
const API_ONBOARDWORKER = '/api/worker/onboardComplete' 
//login the woeker and store the worker in the local storage
const loginWorkerService = async(workerCredentials) =>{
    
    
    const response = await axios.post(API_URL_LOGIN,workerCredentials)
    
    /*
    FIX: If there is an error in the backend 
    the object workerUser will be set to that error message

    TODO: Add more checking around response to check contents
    */
    if(response.status != 200){
        throw Error('Login Failed')
    }

    if(response.data){
        localStorage.setItem('workerUser',JSON.stringify(response.data))
    }

    // console.log(response.data)
    return response.data
}
//logOut
//Does not return a promise, just removes the workerUser from local storage
const logoutWorkerService = () => localStorage.removeItem('workerUser')


const onboardWorker = async (workerInformation, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    try {
        const response = await axios.post(API_ONBOARDWORKER, workerInformation, config);
        // console.log("response.data:", response.data);
        
        return response.data;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || 'Failed to onboard worker';
        throw new Error(message);
    }
};

const workerAuthService = {
    logoutWorkerService,
    loginWorkerService,
    onboardWorker
   
}


export default workerAuthService