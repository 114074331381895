//This component has the form for creating a new jobsite.
//it will first retrieve a signed URL from the backend, and then use that URL to upload the image to S3.
//on submit it will send a post or put request directly to s3 to upload the image, and then send a post request to the backend to create the jobsite.

import { useEffect, useState } from "react";
import {useSelector } from 'react-redux'
import getSignedUrl from "../actions/getPreSignedURL";

import NewSiteImageUploadForm from "./NewSiteImageUploadForm";

const NewSiteForm = ({initialLatitude, initialLongitude, initialAddress, initialRadius}) => {
    //get token from the redux store
    const {token} = useSelector((state) => state.auth.user )
    
    const [formData, setFormData] = useState({
            jobsiteName: "",
            latitude: "",
            longitude: "",
            address: "",
            radius: "",
    });
     //state to hold the signed URL

     const [signedUrlData, setSignedUrlData] = useState({
        s3Url:"",
        received: false,
        jobsiteID: "",
    });

 
    //useEffect to update the latitude,longitude or address inputs change when the user selects a new location on the map or by typing in the address input
    useEffect( ()=> {
        setFormData(prevState => ({
            ...prevState,
            latitude: initialLatitude,
            longitude: initialLongitude,
            address: initialAddress,
            radius: initialRadius,
        }));
    },[initialLatitude, initialLongitude, initialAddress, initialRadius] )

    //useEffect to upload the image to S3 when the signed URL is received
    useEffect(() => {
        if (signedUrlData.received) {
            console.log("Signed URL received");
        }
      }, [signedUrlData]);
    



    // submit formData to the backend and get a signed URL for s3 upload.
    const submitForm = async (e) =>{
       //prevernt page reload
        e.preventDefault();
        
        try{
            //get the presigned URL and jobstieID from the backend. 
            const newSiteData =  await getSignedUrl(token,formData);
            
            setSignedUrlData({
                s3Url: newSiteData.s3URL ,
                received : true ,
                jobsiteID : newSiteData.jobSitePK  });

        }catch(error){
            console.log('Error in submitForm: ' + error);
        }
    }


    return (
        <>  
            {signedUrlData.received ? (
               <NewSiteImageUploadForm signedURL = {signedUrlData.s3Url } jobsiteID={signedUrlData.jobsiteID} />
            ): (

                <form className="mt-1 mb-2  rounded" onSubmit={submitForm}>
                    {/* coordinates */}
                    <div>
                        <div className="flex justify-between  space-x-2">
                            <input type="text" 
                                className="rounded w-1/3 hover:cursor-not-allowed text-white text-sm px-1"  
                                id="address"
                                placeholder={initialAddress}
                                value = {formData.address}
                                disabled
                            />

                            <input type="number" 
                                className="rounded w-1/3 hover:cursor-not-allowed text-white text-sm px-1"  
                                id="radius"
                                placeholder={initialRadius}
                                value = {formData.radius}
                                disabled
                            />
                            
                            <input type="text" 
                                className=" rounded w-1/3 hover:cursor-not-allowed text-white text-sm"
                                id="latitude"
                                placeholder={initialLatitude}
                                value={formData.latitude}
                                disabled
                                
                            />
                            <input type="text" 
                                className="rounded w-1/3 hover:cursor-not-allowed text-white text-sm"  
                                id="longitude"
                                placeholder={initialLongitude}
                                value={formData.longitude}
                                disabled 
                                    
                            />
                            
                        </div>
                    
                    </div>

                    <div className="mt-5">
                        <div className="">
                            <label 
                                htmlFor="jobsiteName"
                                className="sm:text-sm md:text-lg text-white">
                                JobSite Name:
                            </label>
                            <input 
                                className=" p-2 w-full md:w-1/4 ml-2 " 
                                type="text" 
                                id="jobsiteName" 
                                required
                                placeholder="2nd Ave Aparments"
                                value={formData.jobsiteName}
                                onChange={(e) => setFormData((prevState) => ({ ...prevState, jobsiteName: e.target.value }))}
                            />
                        </div>
                    </div>
                    <button className="bg-green-600 hover:bg-green-500 px-2 mt-4 text-white rounded font-sans text-xl" 
                        type="submit">Next</button>
                </form>
            )}
        </>
    );
}

export default NewSiteForm;