//API to to mark attendance


// These are apis are for workerUser.
import axios from "axios";

const punchInAPI = '/api/worker/punchIn'
const punchOutAPI = '/api/worker/punchOut'
const retrievePunchDataAPI = '/api/worker/getPunchData'


//API to punch in
const punchIn = async (punchInData, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await axios.post(punchInAPI, punchInData, config);

    return response.data;
}

//API to punch in
const punchOut = async (punchOutData, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await axios.post(punchOutAPI,punchOutData, config);
    return response.data;
}





//Get the punch data that joins a worker and a jobsite on a given date. 
const retrievePunchData = async (jobsiteId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await axios.post(retrievePunchDataAPI, { jobsiteId }, config);
    console.log(response.data);
    return response.data;
};


const currentJobsiteService = {
    punchIn,
    punchOut,
    retrievePunchData
}

export default currentJobsiteService