import {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {login, reset} from '../features/auth/authSlice'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import Spinner from '../components/Spinner' 
import {Link} from 'react-router-dom'
function Login (){
    //defaults
    const [formData, setFormData] = useState({
        email:'',
        password:'',
    }) 

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {email, password} = formData

    const onChange = (e) =>{
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]:e.target.value,
        }) )
    }


    const {user, isError, isSuccess, isLoading, message} = useSelector(state => state.auth)

    useEffect(()=>{
        if(isError){
            
            toast.error(message)
        }
        if(isSuccess || user){
             
            navigate('/homedash')
            
        }

        dispatch(reset())

    },[isError,isSuccess,user,message,navigate,dispatch])


    const onSubmit = (e)=>{
        e.preventDefault()

        const userData = {
            //comes from form data Ln 17
            email,
            password,
        }
        dispatch(login(userData))
    }

  
    if(isLoading){
        return <Spinner/>
    }
    return(
    <>
        <div className="min-h-screen  flex items-center
             justify-center">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <h2 className="mt-6 text-center text-4xl">
                        Business Login
                    </h2>
                </div>
                <form className="mt-8 space-y-6" onSubmit={onSubmit}>
                    <input type="hidden" name="remember" value="true" />
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                            <label htmlFor="email-address" className="sr-only">Email address</label>
                            <input id="email" name="email" type="email" value={email} onChange={onChange} required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Email address" />
                        </div>
                        <div>
                            <label htmlFor="password" className="sr-only">Password</label>
                            <input id="password" name="password" type="password" value={password}
                                onChange={onChange} required className="appearance-none rounded-none 
                                    relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 
                                        text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500
                                            focus:border-blue-500 focus:z-10 sm:text-sm"
                                         placeholder="Password" />
                        </div>
                    </div>

                    <div className='flex justify-center'>
                        <button type="submit" className="group relative lg:w-1/3  w-full flex justify-center
                         py-2 px-4 border border-transparent text-sm font-medium rounded-md
                              bg-yellow-400 hover:bg-yellow-300 ">
                            Log In
                        </button>
                    </div>
                </form>
                <div className="mt-6 flex justify-center ">
                    <Link to="/resetPassword" className="hover:underline">
                        Forgot Password?
                    </Link>
                </div>

                <div className='flex justify-center'>
                    <Link to="/register" className="lg:w-1/3 w-full flex justify-center">
                        <button type="button" className="group relative w-full flex 
                            justify-center py-2 px-4 text-sm font-medium rounded-md text-white bg-indigo-700 hover:bg-indigo-600">
                            Register
                        </button>
                    </Link>
                </div>
            </div>
        </div>


            
    </>
    )
}

export default Login