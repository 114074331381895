/*
The worker password reset page will be used to reset the password of a worker.
Redux will be used to manage the state of the page. 
The redux states to track are: 
workerUserToken, codeSentStatus, varificationStatus

workerUserToke: the token that is obtained after the worker has been verified, the token is received from the backend. 
codeStatus: the current status of the code. Values can be: 'idle', 'sent', 'error, 'loading', 'notFound'

idle:   the default state of the codeSentStatus, the code has not been sent.
sent:   A matching phone number has been found and a code has been sent to the worker's phone number.
notFound:   The phone number was not found in the database.
error:  An error has occured while sending the code.
*/


import { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


function WorkerPasswordReset() {

    const [phoneValue, setPhoneValue] = useState('');
    const [codeValue, setCodeValue] = useState('');

    const [sentStatus, setSentStatus] = useState(false);

    //Dispatch  is used to call an action from the redux store. 
    const dispatch = useDispatch();   
    const navigate = useNavigate();

    //Redux States
    const workerUserToken = useSelector(state => state.passwordReset.workerUserToken)
    const codeSentStatus  = useSelector(state => state.passwordReset.verification)
    const verificationStatus = useSelector(state => state.passwordReset.isSuccess)  
    
    const handleResendCode = () => {
    // Add logic to handle resending the code
        toast.info(`If registered, code will be re-sent to ${phoneValue}`);
    };

    const sendPhoneVerification = (e) => {
        e.preventDefault();
        // Add logic to handle phone number submission
        toast.info('If registered, you will receive a code via text message');
        //dispatch the action to lookup the phone number and send the code.
        //dispatch(sendCode(phoneValue))
        
        setSentStatus(true);
    };

    const formatPhoneNumber = (value) => {
        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;

        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}`;
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    };

    const handlePhoneChange = (e) => {
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        setPhoneValue(formattedPhoneNumber);
    };

    const handleCodeSubmit = (e) => {
        e.preventDefault();
        // Add logic to handle code validation
    };

  return (
    <>
      <h1 className="text-lg font-sans">Worker password reset</h1>

      {!sentStatus && (
        <form onSubmit={sendPhoneVerification} className="max-w-md mx-auto p-2 rounded-lg mb-4">
          <div className="flex flex-col justify-center items-center">
            <label className="text-md font-medium mb-2" htmlFor="phoneValue">
              Phone number
            </label>
            <input
              className="bg-slate-200 rounded w-full md:w-1/3 py-2 px-3 "
              type="text"
              id="phoneValue"
              pattern="\d{3}-\d{3}-\d{4}"
              maxLength="12"
              required
              value={phoneValue}
              onChange={handlePhoneChange}
            />
          </div>
          <button
            className="bg-black text-white font-medium py-2 px-4 rounded mt-2"
            type="submit"
          >
            Get reset code
          </button>
          
        </form>
      )}

        {/* Show this form if the status is true  */}
        {sentStatus && (
            <form onSubmit={handleCodeSubmit} className="max-w-md mx-auto p-2 rounded-lg">
                <div className="flex flex-col justify-center items-center">
                    <input
                        className="bg-slate-200 rounded w-full md:w-1/3 py-2 px-3 focus:outline-1"
                        type="text"
                        id="codeValue"
                        pattern="\d{6}"
                        maxLength="6"
                        required
                        value={codeValue}
                        onChange={(e) => setCodeValue(e.target.value)}
                        placeholder='Enter code'
                    />
                </div>

                <div className="flex flex-col items-center space-y-4 mt-6">
                    <button
                        className="bg-black text-white  py-2 px-2 rounded font-thin"
                        type="submit"
                    >
                        Verify code
                    </button>

                
                    <button
                        className="bg-black text-white  py-2 px-2 rounded font-thin"
                        type="button"
                        onClick={handleResendCode}
                    >
                        Re-send code
                    </button>

                </div>
                
                
            </form>
        )}

        {/* Modal to appear on successfull code verification */}
    </>
  );
}

export default WorkerPasswordReset;