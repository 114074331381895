import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getWorker } from '../features/workers/workerSlice';
import { getTransactions } from '../features/transactions/transactionsSlice';
import { FaUserEdit, FaCalendar, FaCheckCircle } from 'react-icons/fa';

function WorkerItem({ worker }) {
    const dispatch = useDispatch();

    return (
        <div className='flex flex-wrap justify-center'>
            
            

            <div className='m-2 p-2 bg-gray-100 rounded-lg w-full'>
                
                <div className='flex justify-center items-center'>
                    <img 
                        src={worker.profilePicture ? worker.profilePicture : '/managerWalking.jpeg'} 
                        alt="workerImage" 
                        className="w-20 h-20 object-cover rounded-full"
                    />
                </div>
                
                <div className='p-2'>
                   <div>
                        <h1 className={`text-xl font-light my-0 px-4 capitalize  rounded
                            ${worker.permission === "Supervisor" ? 'bg-yellow-100' : ''}`}>
                            {worker.name}
                        </h1>
                        <h1 className={`text-xl font-light my-0  px-4 capitalize  rounded
                            ${worker.permission === "Supervisor" ? 'bg-yellow-100' : ''}`}>
                            {worker.lastName}
                        </h1>

                   </div>

                    <div className='mt-2'>
                        <div className='text-gray-700 text-sm font-mono'>
                            <p>{worker.role}</p>
                            <p>{`${worker.phone.slice(0, 3)}-${worker.phone.slice(3, 6)}-${worker.phone.slice(6)}`}</p>
                            <p>Hourly rate: ${worker.rate}</p>
                        </div>
                      
                       {/* If onboarded, dispay a check, else display button to re-send onboarding invitation */}
                       {worker.onboarded === true ? (
                                <div className='flex justify-center mt-2'>
                                   <FaCheckCircle className='text-green-500' />
                                </div>
                               
                            ) : (
                                <div className='mt-1'>
                                    
                                    <button className='bg-indigo-300 px-2 rounded font'>Send Reminder</button>
                               
                                </div>
                        )}
                    
                    </div>
                    
                    
                </div>
                <div className='p-4 pt-0 flex justify-between items-center'>
                    <Link to={`/workers/transactionsByWorker/${worker.id}`} className='text-xl' onClick={() => {
                        dispatch(getWorker(worker.id));
                        dispatch(getTransactions(worker.id));
                    }}>
                        <FaCalendar />
                    </Link>
                    {/* Edit worker details */}
                    
                    <Link to={`/workers/update`} state={{ currentWorkerData: worker }} className='text-xl'>
                        <FaUserEdit />
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default WorkerItem;