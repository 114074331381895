//This compoeent calls the FacialLiveness component and passes the onLivenessResult function as a prop.

//uses the liveness ui to ensure the user is alive
//if the return is true we take that image and assign it to this user, we store the image in s3
//the s3 image will be used in the future for rekognition to verify the user. 

// import { useState } from 'react';
import {toast} from 'react-toastify';

import FacialLiveness from '../FacialLiveness'
//is live is passed as a prop from the parent componet, WorkerOnboarding.
//if the user is live take the image and store it in s3. 
const ProfilePictureStep = ({nextStep, onSessionIdChange}) => {
   
    

    // Callback function to be called from the FacialLiveness
    const handleFacialLivenessResult = (isReal, sessionId) => {
       if (isReal === true) {
        onSessionIdChange(sessionId); // Call the function passed from the parent, it assigns the session id to the state
       
        toast.success('Facial Liveness successful!');
        nextStep();  //calls the next step in the onboarding process (parent component)
        
       } else {
        toast.error('Facial Liveness failed. Please try again.');
       }
      
    };
    
    return (
    <>  



        {/* once facial recognition completes submit the gathered onboard data  */}
        <FacialLiveness  onLivenessResult={handleFacialLivenessResult}/>        
   
    </>
        
    );
};

export default ProfilePictureStep;