import {BrowserRouter as Router, Routes,Route} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Homedash from './pages/Homedash';
import Login from './pages/Login';
import PrivacyPolicy from './pages/PrivacyPolicy';

import Home from './pages/Home';
import Register from './pages/Register';
import Header from './components/Header';
import NewSite from './pages/NewSite';
import PrivateRoute from './components/PrivateRoute';
import PrivateWorkerRoute from './components/PrivateWorkerRoute';
import Sites from './pages/Sites';
import Site from './pages/Site';
import NewWorker from './pages/NewWorker';
import Workers from './pages/Workers'
import Transactions from './pages/Transactions'
import Registry from './pages/Registry'
import RegistrAddWorker from './pages/RegistryAddWorker'
import VerifyUser from './pages/VerifyUser';

import PasswordReset from './pages/PasswordReset';
import WorkerPasswordReset from './pages/worker/WorkerPasswordReset';
import UserInfo from './pages/UserInfo';
import NewOrderList from './pages/OrderNewDevicesList'
import UpdateWorker from './pages/UpdateWorkerInfo'
import CreateJobSite from "./pages/CreateJobSite"
import JobSites from './pages/JobSites'

// import FacialLiveness from './components/FacialLiveness'
import WorkerOnboarding from './pages/worker/WorkerOnboarding'
import WorkerLogin from './pages/WorkerLogin'
import WorkerDash from './pages/worker/WorkerDash'
import WorkerCal from './pages/worker/WorkerCal'
import Punch from './pages/worker/Punch'
import AttendanceByJobsite from './pages/AttendanceByJobsite';
function App() {
  return(
      
  <>
  <Router>

      {/* Non protected Routes */}
      
    
      <Header/>
            <Routes>
                  
               
                  <Route exact path="/login" element={<Login />}></Route>
                  <Route exact path="/workerLogin" element={<WorkerLogin />}></Route>
                  <Route exact path="/register" element={<Register />}></Route>
                  <Route exact path="/verify" element={<VerifyUser />}></Route>
                  <Route exact path="/resetPassword" element={<PasswordReset />}> </Route>
                  <Route exact path="/resetWorkerPassword" element={<WorkerPasswordReset />}> </Route>
                  <Route exact path="/privacyPolicy" element={<PrivacyPolicy />}> </Route>
                  

                  {/* Facial Liveness For testing only   */}
                  {/* <Route exact path="/facialRecognition" element={<FacialLiveness />}></Route>   */}
                  

                  {/* PROTECTED PRIVATE WORKER USER ROUTES */}

          

               
                  <Route path='/workerOnboarding' element={<PrivateWorkerRoute />}>
                        <Route exact path="/workerOnboarding" element={<WorkerOnboarding />}></Route>  
                  </Route>

                  <Route path='/workerDash' element={<PrivateWorkerRoute />}>
                        <Route index element={<WorkerDash />} />
                  </Route>

                  <Route path='/calendar/' element={<PrivateWorkerRoute />}>
                        <Route index element={<WorkerCal />} />
                  </Route>

                  <Route path='/punch/:jobsiteId' element={<PrivateWorkerRoute />}>
                        <Route index element={<Punch />} />
                  </Route>






                  {/* PROTECTED PRIVATE USER ROUTES */}
                  {/* TODO : use index in all child protected pages */}
                  <Route path='/homedash' element={<PrivateRoute />}>
                        <Route index element={<Homedash />} />
                  </Route>

                  <Route path='/jobsite/attendance' element={<PrivateRoute />}>
                        <Route index element={<AttendanceByJobsite />} />
                  </Route>

                  <Route path='/orderDevice' element={<PrivateRoute />}>
                        <Route index element={<NewOrderList />} />
                  </Route>

                  <Route path='/newJobSite' element={<PrivateRoute />}>
                        <Route path='/newJobSite' element={<CreateJobSite />} />
                  </Route>
            

                  <Route path='/jobSites' element={<PrivateRoute />}>
                        <Route path='/jobSites' element={<JobSites />} />
                  </Route>



                  {/* This was the old architecture, we will replace this with finger device */}
                  <Route path='/new-site' element={<PrivateRoute />}>
                        <Route path='/new-site' element={<NewSite />} />
                  </Route>


                  <Route path='/sites' element={<PrivateRoute />}>
                        <Route path='/sites' element={<Sites />} />
                  </Route>
                  <Route path='/sites/transactionsBySite/:siteId' element={<PrivateRoute />}>
                        <Route path='/sites/transactionsBySite/:siteId' element={<Site />} />
                  </Route>
                  
                  <Route path='/new-worker' element={<PrivateRoute />}>
                        <Route path='/new-worker' element={<NewWorker />} />
                  </Route>


                  <Route path='/workers' element={<PrivateRoute />}>
                        <Route path='/workers' element={<Workers />} />
                  </Route>

                  <Route path='/workers/update' element={<PrivateRoute />}>
                        <Route path='/workers/update' element={<UpdateWorker/>} />
                  </Route>
                  
                  <Route path='/workers/transactionsByWorker/:workerId' element={<PrivateRoute />}>
                        <Route path='/workers/transactionsByWorker/:workerId' element={<Transactions />} />
                  </Route>
                  <Route path='/sites/:siteId/registry' element={<PrivateRoute />}>
                        <Route path='/sites/:siteId/registry' element={<Registry />} />
                  </Route>
                  <Route path='/sites/:siteId/registry/add' element={<PrivateRoute />}>
                        <Route path='/sites/:siteId/registry/add' element={<RegistrAddWorker />} />
                  </Route>

                  <Route path='/userInfo' element={<PrivateRoute />}>
                        <Route path='/userInfo' element={<UserInfo/>} />
                  </Route>

                  <Route path="/" element={<Home/>}></Route>

            </Routes>


  </Router>
  
  <ToastContainer/>
    
  </>
  )
 
};

export default App;
