import axios from "axios"

// These are backend Routes that get proxied to port 5001
const API_GETWORKERTRANSACTIONS = '/api/worker/monthlyPunchData'   
const API_getAttendanceByJobsite = '/api/dash/getWorkersAtJobsite'  
const API_BackendTest = '/api/dash/test'      

// Get Worker's Transactions by workerId ID
const getMonthlyPunchData_Worker = async (month, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    // Send month as part of the request body
    const response = await axios.post(API_GETWORKERTRANSACTIONS, { month }, config)
    return response.data
}


const getJobsiteAttendance = async (attendanceSearchParams, token) => {
   
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    try {
        // Send attendanceSearchParams as part of the request body
        const response = await axios.post(API_getAttendanceByJobsite, attendanceSearchParams, config)
        
        return response.data
    } catch (error) {
        console.error("Error in fetchAttendanceByJobsite: ", error.response ? error.response.data : error.message)
        throw error
    }
}



const backendTest = async () => {
    try {
        const response = await axios.get(API_BackendTest)
        console.log("Response from backend test: ", response.data)
        return response.data
    } catch (error) {
        console.error("Error in backendTest: ", error.response ? error.response.data : error.message)
        throw error
    }
}






const workHistoryService = {
    getMonthlyPunchData_Worker,
    getJobsiteAttendance,
    backendTest,
}

export default workHistoryService