import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import workerAuthService from './workerAuthService' 

//get user from local storage
const workerUserAuth = JSON.parse(localStorage.getItem('workerUser'))

const initialState = {
    // if there is no user in local storage, set to null
    //workerUser: workerUserAuth? workerUserAuth : null,
    workerUser: workerUserAuth,
    workerLocation: {
        latitude: null,
        longitude: null
    },
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}

//login Worker user
export const loginWorker = createAsyncThunk('workerUser/login', 
    async(workerUserCredentials, thunkAPI) =>{
        //thunkAPI to connect to backEnd 
         try {
            /*
            doesn't catch server errors
            */
           
            // Action.payload
            return await workerAuthService.loginWorkerService(workerUserCredentials)
         } catch (error) {
            // if there is an error return the message from the response or the error message

          

            const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
            console.log(message)
            return thunkAPI.rejectWithValue('Login Attempt Failed')
         }
})


//LogOutWorker Worker user
//logout User
export const logoutWorker = createAsyncThunk('workerUser/logout', async () => {
    workerAuthService.logoutWorkerService();
})




//Perform onboarding of a worker
export const onboardWorker =  createAsyncThunk ('workerUser/onboarding',
    async( workerInformation , thunkAPI) =>{

        try {
            //Get the worker's token
            const token = thunkAPI.getState().workerAuth.workerUser.webToken
            
            return await workerAuthService.onboardWorker(workerInformation , token)
            
        } catch (error) {
            const message = 
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
        console.log(message)
         return thunkAPI.rejectWithValue('Failed to update and onboard worker info')
        }
    }
)



export const workerAuthSlice = createSlice ({
    name: 'workerUser',
    initialState,
    //For non async operations
    reducers:{
        resetWorkerUser: (state) => initialState,
        
        //set the worker's location in the redux store
        setWorkerLocation: (state,action) => {
            state.workerLocation = {
                latitude: action.payload.latitude,
                longitude: action.payload.longitude
            }
        }
    },
    extraReducers : (builder) => {
        builder 
        .addCase(loginWorker.pending, (state) =>{
            state.isLoading = true
        })
        .addCase(loginWorker.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.workerUser = action.payload
        })
        .addCase(loginWorker.rejected, (state, action)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = true
            state.message = action.payload
          
        })
        .addCase(logoutWorker.fulfilled, (state) => {
            state.workerUser = null
        })  
        .addCase(onboardWorker.pending, (state) =>{
            state.isLoading = true
        })
      
        .addCase(onboardWorker.fulfilled, (state, action)=>{
            state.isLoading =false
            state.isSuccess = true
            state.message = action.payload.message
            state.workerUser.onboarded = action.payload.onboarded; 
        })
        .addCase(onboardWorker.rejected, (state , action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload  //mesage from backend
        })
        

    }
})

export const{resetWorkerUser, setWorkerLocation} = workerAuthSlice.actions
export default workerAuthSlice.reducer