// These are functions for User, not the workerUser.
import axios from "axios";

const API_CREATESITE = '/api/dash/createSite'
const API_GETSITES ='/api/dash/getSites'
const API_GETSITEINFO = '/api/dash/getSiteInfo/'
const API_TOGGLEDEVSTATUS = '/api/dash/updateSiteStatus'
//  ->     route (backend)    'dash/sites/:id  yields workers at siteID  -> rename to dash/getWorkersAtSite/:id 


//create new Site
const createSite = async (siteData, token) =>{
    //siteData contains the form Data. This is the data that will be sent to the backend

    const config = {
        headers:{
            Authorization: `Bearer ${token}`,
        },
    }

    //in the old architecture create site was used to create a new fingerDevice.  We need to rename this to createFingerDevice
    const response = await axios.post(API_CREATESITE,siteData,config)
    return response.data
}


//Get All user's Sites
const getSites = async (token) =>{

    const config = {
        headers:{
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.get(API_GETSITES,config)

    
    // if(response.data){
    //     localStorage.setItem('sites',JSON.stringify(response.data))
    // }

    return response.data
}

//get Single site.  Day View 
const getSite = async ( siteID ,token) =>{

    const config = {
        headers:{
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.get(API_GETSITEINFO + siteID ,config)
    return response.data
}

//get Single site.  Day View 
const toggleDevStatus = async ( siteID ,token) =>{

    //this will toggle a site's status
    const data = {
        deviceID : siteID
    }
    
    const config = {
        headers:{
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.post(API_TOGGLEDEVSTATUS, data  ,config)
    return response.data

    //If toggle.status  === 200   feth list of devices again, 
    //after doing the toggle we need to fetch the list of devices again, with updated statuses 
}


const siteService = {
    createSite,
    getSites,
    getSite,
    toggleDevStatus,
}

export default siteService