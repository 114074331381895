//Current jobsite is the jobsite the worker is currently located at.
//It will have the following properties:
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import currentJobsiteService from './currentJobsiteService'

const initialState = {
  jobsite: null,
  distanceAway: null,
  clockedInData: null,
  clockedOutData: null,
  nextPunchType: null,
  isError: null,
  isSuccess: null,
  loaded: false, // This will be used to determine if a jobsite has been selected for clocking in.
  isSelected: false, 
  message: '',
  similarity: null,
  punchMachine: null,
};


// Get today's punch data for a worker at a given jobsite.
//Once the worker is near a jobsite, the worker will be able to see the punch in and punch out times for the day. Wihtout having to press the punch in button.
//It will require a jobsiteID, a token, and a workerID. (Maybe the userId as well)
//This will only execute if the worker is near a jobsite. 

export const retrievePunchData = createAsyncThunk('closestJobsite/getPunchData', 
  async(closestJobsiteId, thunkAPI) =>{
      
      try {
          //get the worker's token
          const workerToken = thunkAPI.getState().workerAuth.workerUser.webToken;
          //console.log(workerToken + " The worker token from retrievePunchData function in slicer");
          return await currentJobsiteService.retrievePunchData(closestJobsiteId, workerToken)
      } catch (error) {
          const message = 
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString()
              return thunkAPI.rejectWithValue(message)
      }
})

// Punch in and punch out actions may be better suited in a different slice file.
//Punch In action
export const punchIn = createAsyncThunk('currentJobsite/punchIn', 
  async(punchData, thunkAPI) => {
      
    try {
          // Get worker token from redux store
          const workerToken = thunkAPI.getState().workerAuth.workerUser.webToken;
          return await currentJobsiteService.punchIn(punchData, workerToken)
      } catch (error) {
          const message = 
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString()
              return thunkAPI.rejectWithValue(message)
      }
})

//punchOut action
export const punchOut = createAsyncThunk('currentJobsite/punchOut', 
  async(punchData, thunkAPI) => {
      try {
          
          const workerToken = thunkAPI.getState().workerAuth.workerUser.webToken;

          return await currentJobsiteService.punchOut(punchData, workerToken)
      } catch (error) {
          const message = 
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString()
              return thunkAPI.rejectWithValue(message)
      }
})


const currentJobsiteSlice = createSlice({
  name: 'currentJobsite',
  initialState,
  reducers: {
    //When user clicks select jobsite, this will be dispatched.
    setJobsite(state, action) {
      state.jobsite = action.payload;
      state.loaded = true;
      state.isError = false;
    },
    setDistanceAway(state, action) {
      state.distanceAway = action.payload;
    },
    setNextPunchType(state,action){
      state.nextPunchType = action.payload;
      state.isSelected = true;
    },
    clearJobsite(state) {
      state.jobsite = null;
    },
    clearPunchState(state) {
      state.isError = null;
      state.isSuccess = null;
      state.message = ''; 
      state.punchMachine = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(punchIn.pending, (state) => {
        state.isError = null;
        state.isSuccess = null;
      })
      .addCase(punchIn.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.message = "Clock in successful";
        state.isError = false;
        state.punchMachine = action.payload; 
        
      })
      .addCase(punchIn.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload || "Clock in failed, try again"; // Use backend error message if available
        state.punchMachine = null; 
        
      })
      .addCase(punchOut.pending, (state) => {
        state.isError = null;
        state.isSuccess = null;
      })
      .addCase(punchOut.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isError = false;
        state.message = "Clock Out successful";
        state.punchMachine = action.payload;
      })
      .addCase(punchOut.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload || "Clock out failed, try again"; // Use backend error message if available
        state.punchMachine = null;
      })
      .addCase(retrievePunchData.pending, (state) => {
        state.isError = null;
        state.isSuccess = null;
      })
      .addCase(retrievePunchData.fulfilled, (state, action) => {
        state.clockedInData = action.payload.clockInData ? action.payload.clockInData.timeIn : null;
        state.clockedOutData = action.payload.clockOutData ? action.payload.clockOutData.timeOut : null;
        state.isSuccess = true;
        state.loaded = true;
      })
      .addCase(retrievePunchData.rejected, (state, action) => {
        state.isError = action.payload;
        state.isSuccess = false;
      });
      
  },
});

export const { setJobsite,
              clearPunchState,
                clearJobsite, 
                setDistanceAway, 
                setNextPunchType,
               } = currentJobsiteSlice.actions;
export default currentJobsiteSlice.reducer;
