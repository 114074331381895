import axios from "axios";

//Routes for usertype:user aka company.
const API_CREATEWORKER = 'api/dash/newWorker'
const API_GETWORKERS = '/api/dash/workers'
const API_GETWORKER = 'api/dash/worker/'
const API_UPDATEWORKER = '/api/dash/updateworker' //absolute path 
const API_TERMINATEWORKER = '/api/dash/terminateWorker' //absolute path


//create new worker 
const createWorker = async (workerData, token) => {
    
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    try {
        const response = await axios.post(API_CREATEWORKER, workerData, config);

        return response.data;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || 'Failed to create worker';
        throw new Error(message);
    }
};

//get all workers belonging to a user
const getWorkers = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    try {
        const response = await axios.get(API_GETWORKERS, config);
        return response.data;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || 'Failed to get workers';
        throw new Error(message);
    }
};



//get Single Worker Info 
const getWorker = async (workerID, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    console.log("workerID:", workerID);
    console.log("token:", token);

    try {   
        const response = await axios.get(`${API_GETWORKER}${workerID}`, config);
        return response.data;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || 'Failed to get worker';
        throw new Error(message);
    }
};  

//update a single worker
const updateWorker = async (updateData, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    try {
        const response = await axios.put(API_UPDATEWORKER, updateData, config);


        return response.data;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || 'Failed to update worker';
        throw new Error(message);
    }
};


//terminate a worker
//updates the redux store workers slice with the new worker data.
const terminateWorker = async (workerID, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    try {
        //passing the workerID in the body of the request.
        const response = await axios.put(
            API_TERMINATEWORKER,
            { workerID },
            config
          );

        return response.data;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || 'Failed to terminate worker';
        throw new Error(message);
    }
};



const workerService = {
    createWorker,
    getWorkers,
    getWorker,
    updateWorker,
    terminateWorker,
   
}

export default workerService