import {useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import  BackButton from '../components/BackButton'
import {toast} from 'react-toastify'
import {createWorker, reset} from '../features/workers/workerSlice'
import Spinner from '../components/Spinner'
import { useEffect } from 'react'

function NewWorker(){
    
   
    const {isLoading, isError, isSuccess, message} = useSelector(
        (state) => state.workers     //-> why workers work and not worker 
    )

    const dispatch = useDispatch();
    const navigate =useNavigate();

    //get user ID from redux we wont change this. 
    
    const [workerLname, setLastName] = useState('')
    const [workerName, setName] = useState('')
    const [workerPhone, setPhone] = useState('')
    const [workerRole, setWorkerRole] = useState('')
    const [workerRate, setRate] = useState('')

   
    useEffect(()=>{
        if(isError){
            toast.error(message)
        }
        if(isSuccess){
            navigate('/workers')
        }

        dispatch(reset)
    },[dispatch,isError,isSuccess,navigate,message])

    const formatPhoneNumber = (value) => {
        if (!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
            return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
        }
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    };

    const handlePhoneChange = (e) => {
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        setPhone(formattedPhoneNumber);
    };

    // Remove all non-digits before submitting to the server.
    const onSubmit =(e) =>{
        e.preventDefault()
        const phoneNumber = workerPhone.replace(/\D/g, '');
        const workerData = {
            workerName,
            workerLname,
            phoneNumber,
            workerRole,
            workerRate,
        }
        dispatch(createWorker(workerData))
    }

    if(isLoading){
        return <Spinner />
    }

   return (

    <>
        <div className='ml-4'>
            
            <BackButton url='/homedash' />

            <div className='ml-4'>
                <h2 className='text-4xl font-bold'> New Worker</h2>
            </div>
        </div>
       
       
       


       <div className='form-group mx-auto max-w-2xl bg-slate-50 p-4 rounded-lg'>
            <form onSubmit={onSubmit}>
            

            <label htmlFor='name'>Name</label>
            <input
                required
                type='text'
                name='name'
                id='name'
                className='form-control'
                placeholder="John"
                value={workerName}
                onChange={(e) => setName(e.target.value)}
            ></input>

            <label htmlFor='workerLname'>Last Name</label>
            <input
                required
                type='text'
                name='workerLname'
                id='workerLname'
                className='form-control'
                placeholder="Doe"
                value={workerLname}
                onChange={(e) => setLastName(e.target.value)}
            ></input>

            <label htmlFor='phone'>Phone Number</label>
            
            <div className='form-group'>
                <input
                    type='tel'
                    name='phone'
                    pattern="\d{3}-\d{3}-\d{4}"
                    maxLength="13"
                    id='phone'
                    className='form-control'
                    placeholder="347-123-1234"
                    value={workerPhone}
                    onChange={handlePhoneChange}
                ></input>

            </div>
            

         

            <label htmlFor='workerRole'>Role</label>
            <input
                required
                type='text'
                name='workerRole'
                id='workerRole'
                className='form-control'
                placeholder="Metal Framer"
                value={workerRole}
                onChange={(e) => setWorkerRole(e.target.value)}
            ></input>

            <label htmlFor='workerRate'>Hourly Rate</label>
            <input
                required
                type='number'
                name='workerRate'
                id='workerRate'
                className='form-control'
                placeholder="25.75"
                value={workerRate}
                onChange={(e) => setRate(e.target.value)}
            

            ></input>   

            <div className='form-group flex justify-center'>
                <button className='bg-black text-white p-2  rounded-lg'>
                     Submit
                </button>
            
            </div>

            
               
            </form>
             
        </div>
    </>

   )

}

export default NewWorker