import {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {loginWorker} from '../features/workerAuth/workerAuthSlice'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import Spinner from '../components/Spinner' 
import {Link} from 'react-router-dom'
import {FaClock} from 'react-icons/fa'

function WorkerLogin (){
    const [phoneNumber, setPhoneNumber] = useState('')
    const [password, setPassword] = useState('')    

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {workerUser, isError, isSuccess, isLoading, message} = useSelector(state => state.workerAuth)

    useEffect(()=>{
        if(isError){
            toast.error(message)
        }
        if(isSuccess && workerUser){
            navigate('/workerDash')
        }
    },[isError,isSuccess,workerUser,message,navigate,dispatch])

    const onChange = (e) => {
        setPassword(e.target.value);
    }

    const stylePhoneNumber = (e) => {
        let value = e.target.value;
        value = value.replace(/\D/g, ""); // Remove all non-numeric characters
        value = value.substring(0, 10); // Limit to max 10 digits

        // Add dashes
        if (value.length > 6) {
            value = value.substring(0, 3) + "-" + value.substring(3, 6) + "-" + value.substring(6);
        } else if (value.length > 3) {
            value = value.substring(0, 3) + "-" + value.substring(3);
        }

        setPhoneNumber(value);
    }   

    const onSubmit = (e)=>{
        e.preventDefault()
        
        const workerPhone = phoneNumber.replace(/-/g, "")
        
        const workerCredentials = {
            phoneNumber : workerPhone,
            password
        }

        dispatch(loginWorker(workerCredentials))

        if(isSuccess){
            navigate('/workerDash')
        }

        if(isError){
            toast.error(message)
        }
    }
  
    if(isLoading){
        return <Spinner/>
    }

    return(
    <>
        <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl w-full grid grid-cols-1 lg:grid-cols-2 gap-8">
                <div className="hidden lg:flex flex-col items-center justify-center">
                    <h1 className="text-5xl font-semibold text-gray-900">TIMESAT</h1>
                    <p className="mt-4 text-xl text-gray-700">Fraud-free jobsite attendance tracking</p>
                </div>
                
                <div className="max-w-md w-full mx-auto lg:mx-0 space-y-8 bg-white p-8 rounded-lg shadow-sm">
                    <div>
                        <FaClock className="mx-auto h-12 w-auto"/>
                        <h2 className="mt-4 text-center text-4xl font-semibold ">
                            Worker Login
                        </h2>
                    </div>

                    <form className="mt-8 space-y-6" onSubmit={onSubmit}>
                        <input type="hidden" name="remember" value="true" />
                        <div className="rounded-md shadow-sm -space-y-px">
                            <div>
                                <label htmlFor="phoneNumber" className="sr-only">Phone Number</label>
                                <input 
                                    id="phoneNumber" 
                                    name="phoneNumber" 
                                    type="text" 
                                    value={phoneNumber} 
                                    onChange={stylePhoneNumber} 
                                    required 
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border
                                         border-gray-200 placeholder-gray-500
                                         text-gray-900 rounded-t-md focus:outline-none  focus:border-black focus:z-10 sm:text-sm" 
                                    placeholder="(347)123-1234" 
                                />
                            </div>
                            <div>
                                <label htmlFor="password" className="sr-only">Password</label>
                                <input 
                                    id="password" 
                                    name="password" 
                                    type="password" 
                                    value={password}
                                    onChange={onChange} 
                                    required 
                                    className="appearance-none rounded-none 
                                        relative block w-full px-3 py-2 border border-gray-200 placeholder-gray-500 
                                            text-gray-900 rounded-b-md focus:outline-none 
                                                focus:border-black focus:z-10 sm:text-sm"
                                    placeholder="Password" />
                            </div>
                        </div>

                        <div>
                            <button type="submit" 
                                    className="group relative w-full flex justify-center
                                                py-2 px-4 text-lg font-medium rounded-md
                                                text-black bg-yellow-500 hover:bg-yellow-600">
                                Log In
                            </button>
                        </div>
                    
                    </form>
                    {/* End of Form  */}

                    
                    <div className="mt-6 text-center">
                        <Link to="/resetWorkerPassword" className="underline p-2 rounded-md">
                            Forgot Password ?
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default WorkerLogin