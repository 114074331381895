import React from 'react';

const callouts = [
  {
    name: 'Save Time',
    description: 'Digitize your attendance tracking and save time. No more manual data entry, no more paper time sheets, no more manual calculations, no more fraud.',
    imageSrc: '/frustratedMan.jpeg',
    imageAlt: 'Frustrated Man',
    href: '#',
  },
  {
    name: 'Cumbersome solutions',
    description: 'Stop managing key fobs and ID cards. TimeStat uses facial recognition to track attendance, so you can stop printing, replacing key fobs and ID cards for every worker.',
    imageSrc: '/oldSolutions.png',
    imageAlt: 'Key Fobs and ID Cards',
    href: '#',
  },
  {
    name: 'Be more productive',
    description: "Your foreman can focus on other tasks and not worry about tracking attendance. TimeStat will do the work for you.",
    imageSrc: '/foreman.png',
    imageAlt: 'Foreman Instructing',
    href: '#',
  },
];

export default function OldWay() {
  return (
    <div className="md:bg-slate-200 mt-4 pt-8 pb-12" style={{ backgroundImage: 'url(/paper-texture.png)', backgroundSize: 'cover' }}>
      <div className="mx-auto md:max-w-none lg:max-w-screen-lg lg:py-12">
        <h2 className="text-4xl md:text-5xl font-serif mb-10 text-center text-gray-800">
          Solutions of the Past
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {callouts.map((callout) => (
            <div
              key={callout.name}
              className="group relative rounded-xl overflow-hidden bg-gray-100 border border-gray-300 shadow-md hover:shadow-lg transition-shadow duration-500"
            >
              <div className="h-64 w-full overflow-hidden">
                <img
                  src={callout.imageSrc}
                  alt={callout.imageAlt}
                  className="h-full w-full object-cover object-center transition-transform duration-500 group-hover:scale-105 sepia"
                />
              </div>
              <div className="p-6">
                <h3 className="text-2xl font-serif text-gray-900">
                  <a href={callout.href} className="hover:underline">
                    {callout.name}
                  </a>
                </h3>
                <p className="mt-4 text-gray-700">{callout.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}