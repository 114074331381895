//This file will submit the form data to the backend, and return a signed URL for uploading the image to S3

import axios from "axios";

//api endpoint for getting a signed URL from the backend
const API_S3_SignedURL = '/api/dash/signedURL';

//this function will retireve a preSigned URL from the backend usiing the api endpoint
const getSignedUrl = async (token, formData ) => {
    
    try {
        const response = await axios.post(API_S3_SignedURL,formData,{
            headers: {
                'Authorization': `Bearer ${token}`
            },
    
        });

        //get the s3 url and the new jobsite ID from the response. 
        return response.data
        
    } catch (error) {
        console.log('Error getting signed URL: ' + error);
        throw error;
    }
    


}

export default getSignedUrl;