//User Registration Page
import {useState,useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
//select anything from global state
import {useSelector} from 'react-redux'
//dispatch our action
import {useDispatch} from 'react-redux'
import {register} from '../features/auth/authSlice'
import Spinner from '../components/Spinner'

function Register (){
    const [agreement, setAgreement] = useState(false)
    const [formData, setFormData] = useState({
        companyName:'',
        name:'',
        email:'',
        phone:'',
        password:'',
        password2:''
    }) 

    const {companyName, name, email, phone, password, password2} = formData

    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    //get current values from global state redux
    const {user, isError, isSuccess, isLoading, message} = useSelector(state => state.auth)

     useEffect(()=>{
        if(isError){
            
            toast.error(message)
        }
        if(isSuccess || user){
             
            navigate('/verify')
            

        }

        //do no reset, instead send verification code
        // dispatch(reset())

    },[isError,isSuccess,user,message,navigate,dispatch])



    const onChange = (e) => {
        if (e.target.name === "phone") {
          // remove all non-numeric characters from the input value
          const numericValue = e.target.value.replace(/\D/g, '');
          // limit the input to accept only 10 digits
          const limitedValue = numericValue.substring(0, 10);
          // add "-" after 3rd and 6th digits
          let formattedValue = '';
          for (let i = 0; i < limitedValue.length; i++) {
            if (i === 3 || i === 6) {
              formattedValue += '-';
            }
            formattedValue += limitedValue.charAt(i);
          }
          setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: formattedValue,
          }));
        } else {
          setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
          }));
        }
      };
      
    

    const onSubmit = (e) =>{
        e.preventDefault()
        if (!agreement) {
            toast.warning('You must agree to the privacy policy before submitting.');
            return;
        }

        if(password !== password2){
            toast.error("passowords do not match")
            return;
        }

        const userData = {
                name,
                email,
                phone,
                password,
                companyName,
        }
        dispatch(register(userData));    
    }

    if(isLoading) {
        return<Spinner/>
    }

    return(
        <>  
        <div className='bg-green-950 min-h-screen flex items-center justify-center mt-0 pt-16 md:pt-0'>
            <div className="w-full flex flex-col md:flex-row rounded-lg mx-4">
                {/* Left Side */}
                <div className="w-full md:w-1/2 bg-green-950 flex flex-col items-start justify-center rounded-t-lg md:rounded-l-lg md:rounded-t-none mr-2">
                    <h1 className="text-5xl font-normal text-green-400 mt-6">Get Started</h1>
                    <p className="text-lg font-normal text-white mt-4 text-left">
                        No matter the size of your business or the industry you’re in, TimeStat can help you do more with time tracking.
                        
                    </p>
                </div>
    
                {/* Right Side */}
                <div className="w-full md:w-1/2 p-6 bg-white rounded-b-lg md:rounded-r-lg md:rounded-b-none ml-2">
                    <section>
                        <p className="font-normal mt-6 text-center px-2">
                            For businesses who want to get started, please fill out the form below.
                        </p>
                    </section>
    
                    <section className='form pl-2 pr-6 py-4'>
                        <form onSubmit={onSubmit}>
    
                            <div className='form-group mb-4 text-left'>
                                <label htmlFor="companyName" className="block text-gray-700 text-md font-kanit font-medium mb-2">Business Name </label>
                                <input className='appearance-none w-full py-2 px-3 leading-tight focus:outline-none focus:border-blue-500'
                                    type='text' id='companyName' name='companyName' value={companyName} onChange={onChange} placeholder='DryWall Contracting' required>
                                </input>
                            </div>
    
                            <div className='form-group mb-4 text-left'>
                                <label htmlFor="name" className="block text-gray-700 text-md font-kanit font-medium mb-2">Contact Person </label>
                                <input className='appearance-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500'
                                type='text' id='name' name='name' value={name} onChange={onChange} placeholder='Enter Your name' required>
                                </input>
                            </div>
    
                            <div className='form-group mb-4 text-left'>
                                <label htmlFor="email" className="block text-gray-700 text-md font-kanit font-medium mb-2">Business Email</label>
                                <input className='appearance-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500'
                                type='email' id='email' name='email' value={email} onChange={onChange} placeholder='person@Business.com' required>
                                </input>
                            </div>
    
                            <div className='form-group mb-4 text-left'>
                                <label htmlFor="phone" className="block text-gray-700 text-md font-kanit font-medium mb-2">Business Phone Number</label>
                                <p className='text-sm text-gray-400'> ** Must be able to receive text messages</p>
                                <input className='appearance-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500'
                                type='tel' id='phone' name='phone' value={phone} onChange={onChange} placeholder='123-123-1234' required>
                                </input>
                            </div>
    
                            <div className='form-group mb-4 text-left'>
                                <label htmlFor="password" className="block text-gray-700 text-md font-kanit font-medium mb-2">Password</label>
                                <input className='appearance-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500'
                                    type='password' 
                                    id='password' 
                                    name='password' 
                                    value={password} 
                                    onChange={onChange} 
                                    placeholder='$ecurePa$$w0rd123' required>
                                </input>
                            </div>
    
                            <div className='form-group mb-6 text-left'>
                                <label htmlFor="password2" className="block text-gray-700 text-md font-kanit font-medium mb-2">Confirm Password</label>
                                <input className='appearance-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500'
                                type='password' 
                                id='password2' 
                                name='password2' 
                                value={password2} 
                                onChange={onChange} 
                                placeholder='Confirm Password' required>
                                </input>
                            </div>
    
                            <div className='mb-4 text-left'>
                                <label className='inline-flex items-center text-sm'>
                                    <input type='checkbox' 
                                        className='form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 focus:ring-blue-500'
                                        onChange={(e) => setAgreement(e.target.checked)}
                                        />
                                    <span className='ml-2 text-gray-700 font-thin'>I agree to the <a href='/privacyPolicy' className='text-blue-600'>Privacy Policy</a> and to receive SMS verification code</span>
                                </label>
                            </div>
                            
                            <div className='flex justify-center'>
                                <button className='bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 focus:outline-none' type="submit">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </section>
                </div>
            </div>
        </div>
               
        </>
        
    )
  }


  export default Register