import React, { useEffect, useRef, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";

//onLocationChange is a callback function that will be used to update the state in NewSiteForm
function GoogleMapsComponent({onLocationChange}) {
  const [radius, setRadius] = useState(30);
  const radiusRef = useRef(radius);
  const mapRef = useRef(null);

  useEffect(() => {
    radiusRef.current = radius;
  }, [radius]);

  useEffect(() => {
    let marker = null;  
    let circle = null;

    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
      version: "weekly",
      libraries: ["places"],
    });

    loader.load().then(() => {
      const map = new window.google.maps.Map(mapRef.current, {
        center: { lat: 40.7128, lng: -74.0060 },
        zoom: 11,
      });

      // Create an autocomplete input
      const input = document.getElementById("autocomplete-input");
      const autocomplete = new window.google.maps.places.Autocomplete(input);
      
      // Set the bounds to the map's viewport for better results
      autocomplete.bindTo("bounds", map);

      // Add a listener to handle the place changed event
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();

        if (!place.geometry) {
          console.error("Place details not found for input:", input.value);
          return;
        }

        const { location } = place.geometry;
        
        const lat = location.lat();
        const lng = location.lng();
        const addr = place.formatted_address;

        //set the map's center and zoom to the selected location
        map.setCenter(location);
        map.setZoom(15);

        if(marker){
            marker.setMap(null);
          }

        //place a marker on the typed location 
        marker = new window.google.maps.Marker({
            position: location,
            map,
        });

        if (circle) {
          circle.setMap(null);
        }

        circle = new window.google.maps.Circle({
          map,
          radius: radiusRef.current * 0.3048, // convert feet to meters
          center: location,
          editable: true,
          draggable: false,
          fillColor: "#FF0000",
        });

        // Add an event listener for the radius_changed event
        circle.addListener('radius_changed', () => {
          const newRadius = circle.getRadius() / 0.3048; // convert meters to feet
          setRadius(newRadius);
          onLocationChange({ lat, lng, addr, radius: newRadius });
        });

        //update the state in NewSiteForm by calling the callback function passed as a prop
        onLocationChange({lat,lng,addr,radius: radiusRef.current});  //include a radius property

      });

      //listener for map click event
      map.addListener("click", (event) =>{
        const clickedLocation = event.latLng;
        const lat = clickedLocation.lat();
        const lng = clickedLocation.lng();
 
        if(marker){
          marker.setMap(null);
        }
        //place a marker on the clicked location 
        marker = new window.google.maps.Marker({
          position: clickedLocation,
          map,
        });

        if(circle){
          circle.setMap(null);
        }

        circle = new window.google.maps.Circle({
          map,
          radius: radiusRef.current * 0.3048, // convert feet to meters
          center: clickedLocation,
          editable: true,
          draggable: false,
          fillColor: "#FF0000",
        });

        // Add an event listener for the radius_changed event
        circle.addListener('radius_changed', () => {
          const newRadius = circle.getRadius() / 0.3048; // convert meters to feet
          setRadius(newRadius);
          onLocationChange({ lat, lng, addr: input.value, radius: newRadius });
        });

        //use the geocode api to get the address of the clicked location
        const geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({location: clickedLocation}, (results, status)=>{
          if(status === "OK"){
            if(results[0]){
              const addr = results[0].formatted_address;
              console.log(`Clicked on the map - Latitude: ${lat} || Longitude: ${lng} || Address: ${addr} `)
              
              //update the state in NewSiteForm by calling the callback function passed as a prop
              onLocationChange({ lat, lng, addr, radius: radiusRef.current });  //include a radius property
              // Set the value of the autocomplete input to the address
              input.value = addr;
            }else{
              window.alert("No results found");
            }
          }else{
            window.alert("Geocoder failed due to: " + status);
          }
        });

      });
    });
  }, [onLocationChange]);

  return (
    <div className="mb-2 ">
      <div className="mt-2 mb-2">
        <input
          id="autocomplete-input"
          type="text"
          placeholder="Type Address or Click on Map"
          style={{ width: "300px", padding: "10px" }}
          className="rounded"
        />
      </div>
      <div ref={mapRef} style={{ height: "50vh", width: "100%", borderRadius: "15px" }}></div>
      
    </div>
  );
}

export default GoogleMapsComponent;