import { useState } from 'react';
import {toast} from 'react-toastify';

const SsnStep = ({setSsn , nextStep}) => {
    const [SocialOrItin, setSocialorItin] = useState("");
   
    const handleChange = (event) => {
        setSocialorItin(event.target.value);
    };

    const handleNextStep = () => {
        //check if the ssn is valid.
        const ssnRegex = /^\d{9}$/;
        if (SocialOrItin.length === 0) {
            toast.error("SSN or ITIN is required.");
        } else if (!ssnRegex.test(SocialOrItin)) {
            toast.error("Invalid SSN or ITIN.");
        } else {
            //set the ssn state in the parent component and move to the next step.
            setSsn(SocialOrItin); 
            nextStep();
        }
    };

    return (
        <div className='flex flex-col'>
            <div className=''>
                <label htmlFor="ssn"> SSN or ITIN:</label>
                <input
                    type="text"
                    id="ssn"
                    value={SocialOrItin}
                    onChange={handleChange}
                    className='border border-black rounded p-1 ml-1'
                    pattern="\d{9}"
                    title="SSN must be exactly 9 digits."
                    maxLength={9}
                />

            </div>
                
          
            <div className='mt-2'>
                <button onClick={handleNextStep}
                        className='bg-indigo-700 text-white p-2 rounded-lg'
                    >Next
                </button>

            </div>
           
        </div>
    );
};

export default SsnStep;