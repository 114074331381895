import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import workerService from './workerService' 

const initialState = {
    workers : [],
    workerInfo: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',

}

//Create new worker 
export const createWorker = createAsyncThunk('workers/create', 
    async(workerData, thunkAPI) =>{
        //thunkAPI to connect to backEnd 
        try {
            const token = thunkAPI.getState().auth.user.token
            return await workerService.createWorker(workerData,token)
        } catch (error) {
            const message = 
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
            console.log(message)
                return thunkAPI.rejectWithValue('Failed to create worker')
        }
})


//get user all wokers
export const getWorkers = createAsyncThunk('workers/getAll', 
    async( _, thunkAPI) =>{
        //thunkAPI to connect to backEnd 
        try {
            const token = thunkAPI.getState().auth.user.token
            return await workerService.getWorkers(token)
        } catch (error) {
            const message = 
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
            
            return thunkAPI.rejectWithValue(message)
        }
})

//get a  user worker's information
export const getWorker = createAsyncThunk('workers/getSingleWorker', 
    async( workerId, thunkAPI) =>{
        //thunkAPI to connect to backEnd 
        try {
            const token = thunkAPI.getState().auth.user.token
            return await workerService.getWorker( workerId,token)
        } catch (error) {
            const message = 
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        
        return thunkAPI.rejectWithValue(message)
        }
})


//update a worker data
export const updateWorker =  createAsyncThunk ('worker-update',
        async( updateData , thunkAPI) =>{
    
            try {
                const token = thunkAPI.getState().auth.user.token
                return await workerService.updateWorker ( updateData , token)
            } catch (error) {
                const message = 
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
            console.log(message)
             return thunkAPI.rejectWithValue('Failed to update worker info')
            }
        }
)


//terminate a worker at a company(user)
export const terminateWorker =  createAsyncThunk ('worker-terminate',
        async( workerToTerminate , thunkAPI) =>{
          
            try {
                const token = thunkAPI.getState().auth.user.token
                return await workerService.terminateWorker( workerToTerminate , token)
            } catch (error) {
                const message = 
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        
             return thunkAPI.rejectWithValue(message)
            }
        }
)

export const workerSlice = createSlice ({
    name: 'worker',
    initialState,
    reducers:{

        reset: (state) => initialState,
        
    },
    extraReducers : (builder) => {
        builder 
            .addCase(createWorker.pending, (state) => {
                state.isLoading = true
            })

            .addCase(createWorker.fulfilled, (state) => {
                state.isLoading = false
                state.isSuccess = true
            })
            .addCase(createWorker.rejected, (state,action) => {
                state.isLoading = false
                state.isError =true
                state.message = action.payload
            })

            .addCase(getWorkers.pending, (state) => {
                state.isLoading = true
            })

            .addCase(getWorkers.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.workers = action.payload
            })
            .addCase(getWorkers.rejected, (state,action) => {
                state.isLoading = false
                state.isError =true
                state.message = action.payload
            })
            .addCase(getWorker.pending, (state) =>{
                state.isLoading = true
            })
            .addCase(getWorker.fulfilled, (state , action)=>{
                state.isLoading =false
                state.isSuccess = true
                state.workers = Array.isArray(action.payload) ? action.payload : [];
                
            })
            .addCase(getWorker.rejected, (state, action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(updateWorker.pending, (state) =>{
                state.isLoading = true
            })
          
            .addCase(updateWorker.fulfilled, (state)=>{
                state.isLoading =false
                state.isSuccess = true
                state.message = "Updated Successfully"
            })
            .addCase(updateWorker.rejected, (state , action)=>{
                state.isLoading =false
                state.isError = true
                state.message = action.payload  //mesage from backend
               
            })
            .addCase(terminateWorker.pending, (state) =>{
                state.isLoading = true
            })
            .addCase(terminateWorker.fulfilled, (state, action)=>{
                state.isLoading =false
                state.isSuccess = true
                state.message = action.payload   //message from backend
            })
            .addCase(terminateWorker.rejected, (state, action)=>{
                state.isLoading =false
                state.isError = true
                state.message = action.payload
            })
    }
})

export const{reset} = workerSlice.actions
export default workerSlice.reducer