import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import siteService from './siteService' 
import { toast } from 'react-toastify';

// const sitesW = JSON.parse(localStorage.getItem('sites'))

// const initialState = {
//     sites: sitesW ? sitesW : 'null',
//     site: {},
//     isError: false,
//     isSuccess: false,
//     isLoading: false,
//     message: ''
// }

const initialState = {
    sites: [],
    siteInfo: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

//Create new Site
export const createSite = createAsyncThunk('sites/create', 
    async(siteData, thunkAPI) =>{
        //thunkAPI to connect to backEnd 
        try {
            const token = thunkAPI.getState().auth.user.token
            return await siteService.createSite(siteData, token)
        } catch (error) {
            const message = 
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
                return thunkAPI.rejectWithValue(message)
        }
})

//get  all of a  User's Sites
export const getSites = createAsyncThunk('sites/getAll', 
    async( _, thunkAPI) =>{
        //thunkAPI to connect to backEnd 
        try {
            const token = thunkAPI.getState().auth.user.token
            return await siteService.getSites(token)
        } catch (error) {
            const message = 
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        
        return thunkAPI.rejectWithValue(message)
        }
})

//get a user's single site's infromation.
export const getSite = createAsyncThunk('sites/getSingleSite', 
    async( siteId, thunkAPI) =>{
        //thunkAPI to connect to backEnd 
        try {
            const token = thunkAPI.getState().auth.user.token
            return await siteService.getSite( siteId,token)
        } catch (error) {
            const message = 
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        
        return thunkAPI.rejectWithValue(message)
        }
})

export const toggleDeviceStatus = createAsyncThunk('sites/toggleDevStatus', 
    async( siteId, thunkAPI) =>{
        //thunkAPI to connect to backEnd 
        try {
            const token = thunkAPI.getState().auth.user.token
            return await siteService.toggleDevStatus( siteId,token)
        } catch (error) {
            const message = 
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
            
            toast.info(error.response.data.error); // Show error message using react-toastify
            // console.log(error)
        return thunkAPI.rejectWithValue(message)
        }
})

export const siteSlice = createSlice({
    name:'site',
    initialState,
    reducers:{
        reset: (state) => initialState,
        
      
    },
    extraReducers: (builder) =>{
        builder 
            .addCase(createSite.pending, (state)=>{
                state.isLoading = true
            })
            .addCase(createSite.fulfilled, (state)=>{
                state.isLoading = true
                state.isSuccess = true
            }) 
            .addCase(createSite.rejected, (state, action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getSites.pending, (state)=>{
                state.isLoading = true
            })
            .addCase(getSites.fulfilled, (state, action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.sites = action.payload
            }) 
            .addCase(getSites.rejected, (state, action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getSite.pending, (state)=>{
                state.isLoading = true
            })
            .addCase(getSite.fulfilled, (state, action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.siteInfo = action.payload
            }) 
            .addCase(getSite.rejected, (state, action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(toggleDeviceStatus.pending, (state)=>{
                state.isLoading = true
            })
            .addCase(toggleDeviceStatus.fulfilled, (state, action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.message = action.payload
            }) 
            .addCase(toggleDeviceStatus.rejected, (state, action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    },
})

export const  {reset} = siteSlice.actions
export default siteSlice.reducer