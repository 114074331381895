import React from 'react';

const Faq = () => {
  return (
    <>
      <section className="text-gray-700 p-2 mt-4">
        <div className="container px-2">
          <div className="text-center mb-2">
            <h1 className="text-2xl font-mono text-center">
              Frequently Asked Questions
            </h1>
           
          </div>
          <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
            <div className="w-full lg:w-1/2 px-4 py-2">
              <details className="mb-4">
                <summary className="font-semibold bg-gray-200 rounded-md py-2 px-4" aria-expanded="false" aria-controls="question1-content">
                  How many workers can I have?
                </summary>
                <span id="question1-content">
                  Hardware devices up to 200 worker per jobsite.
                  For the webApp, unlimited workers.
                </span>
              </details>

              <details className="mb-4">
                <summary className="font-semibold bg-gray-200 rounded-md py-2 px-4">
                What if I finished a job?
                </summary>

                <span>
                You may use existing device at another location. De-activate the device until you need it again, or if it's in good condition send it back to us and we will credit your account. 
                </span>
              </details>
              <details className="mb-4">
                <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                How many devices do I need?
                </summary>

                <span>
                Hardware devices are meant to be deployed per jobssite, each device is assigned a location.
                If you opt for the webApp solution, you don't need any devices. Just dowload the app and start using it.
                </span>
              </details>
            </div>
            <div className="w-full lg:w-1/2 px-4 py-2">
              <details className="mb-4">
                <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                Does it work outdoors?
                </summary>

                <span className="px-4 py-2">
                Yes, we even tested it on top of Mt.Everest. 
                </span>
              </details>
              <details className="mb-4">
                <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                I have a trucking company, can it move around ?
                </summary>

                <span className="px-4 py-2">
                Yes, timeStat hardware can be moved all around the city, state, or country (Continental United States)
                </span>
              </details>
              <details className="mb-4">
                <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                 Are the fingerprints secured?
                </summary>

                <span className="px-4 py-2">
                Yes, they remain encrypted on your device and we never see them.
                </span>
              </details>
              
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faq;

