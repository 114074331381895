import React, { useEffect } from 'react';
import Calendar from 'react-calendar';
import chroma from 'chroma-js';
import 'react-calendar/dist/Calendar.css';
import './WorkerCal.css';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkerViewHistory } from '../../features/workHistory/workHistroySlice';

const WorkerCal = () => {
    const dispatch = useDispatch();
    const { workerView_monthlyPunchData, isError, isSuccess, isLoading } = useSelector(state => state.workHistory);

    // Get distinct jobsite UUIDs
    const distinctJobsites = Array.from(new Set(workerView_monthlyPunchData.map(entry => entry.jobSiteID)));

    // Generate a color for each distinct jobsite UUID using chroma-js with better contrast
    // Use a color scale that avoids light colors like yellow
    const colorScale = chroma.scale(['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', 
                                    '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'])
                             .mode('lch')
                             .colors(distinctJobsites.length);
                             
    // Ensure all colors have sufficient contrast with white background
    const jobsiteColors = distinctJobsites.reduce((acc, uuid, index) => {
        let color = colorScale[index];
        // If contrast ratio is too low (color too light), darken it
        while (chroma.contrast(color, '#ffffff') < 3) {
            color = chroma(color).darken().hex();
        }
        acc[uuid] = color;
        return acc;
    }, {});

    /**
     * Function to get the colors for a specific date
     * @param {Date} date - The date for which to get the colors
     * @returns {Array<string>} - The colors corresponding to the jobsites on that date
     */
    const getDateColors = (date) => {
        const entries = workerView_monthlyPunchData.filter(entry => new Date(entry.localDate).toISOString().split('T')[0] === date.toISOString().split('T')[0]);
        return entries.map(entry => jobsiteColors[entry.jobSiteID]);
    };

    /**
     * Function to render a tile in the calendar
     * @param {Object} param0 - The properties of the tile
     * @param {Date} param0.date - The date of the tile
     * @param {string} param0.view - The view type of the calendar (e.g., 'month')
     * @returns {JSX.Element|null} - The content to render inside the tile, or null if no content
     */
    const tileContent = ({ date, view }) => {
        if (view === 'month') {
            const colors = getDateColors(date);
            if (colors.length > 0) {
                // Fix gradient formatting to ensure proper display
                const gradient = colors.length > 1 
                    ? `linear-gradient(to right, ${colors.join(', ')})` 
                    : colors[0];
                
                // Use opacity to ensure date numbers remain visible
                return <div className="color-tile" style={{ background: gradient }}></div>;
            }
        }
        return null;
    };

    /**
     * Function to add a custom class to a tile in the calendar
     * @param {Object} param0 - The properties of the tile
     * @param {Date} param0.date - The date of the tile
     * @param {string} param0.view - The view type of the calendar (e.g., 'month')
     * @returns {string|null} - The custom class to add to the tile, or null if no class should be added
     */
    const tileClassName = ({ date, view }) => {
        if (view === 'month') {
            const entry = workerView_monthlyPunchData.find(entry => new Date(entry.localDate).toISOString().split('T')[0] === date.toISOString().split('T')[0]);
            return entry ? 'highlight' : null;
        }
    };

    const handleActiveStartDateChange = ({ activeStartDate, view }) => {
        if (view === 'month') {
            const month = activeStartDate.toISOString();
            dispatch(getWorkerViewHistory(month));
        }
    };

    // When the component mounts, dispatch the action to get the worker's work history for the current month
    useEffect(() => {
        const currentMonth = new Date().toISOString();
        dispatch(getWorkerViewHistory(currentMonth));
    }, [dispatch]);

    return (
        <>  
            <div className='flex justify-center mt-4'>
                <Calendar
                    tileContent={tileContent}
                    tileClassName={tileClassName}
                    className="w-full sm:w-auto custom-calendar"
                    onActiveStartDateChange={handleActiveStartDateChange}
                    prev2Label={null}
                    next2Label={null}
                />
            </div>

            {/* Add spacing between the calendar and the jobsite names */}
            <div className='mt-8'></div>

            <div className='container flex flex-wrap justify-center'>
                {distinctJobsites.map((jobSiteID, index) => {
                    // Find the jobsite name corresponding to the current UUID
                    const jobsite = workerView_monthlyPunchData.find(entry => entry.jobSiteID === jobSiteID)?.jobsiteName || "Unknown Site";
                    return (
                        <div key={index} className="flex items-center mr-4 mb-2">
                            {/* Display a color-coded square for the jobsite */}
                            <div className="w-4 h-4 mr-2" style={{ backgroundColor: jobsiteColors[jobSiteID] }}></div>
                            <div>
                                <p>{jobsite}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default WorkerCal;