import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import workHistoryService from './workHistroyService' 

// The state may only have a single workerView, employerView_byWorker, employerView_byJobsite populated at a time.
const initialState = {
    workerView_monthlyPunchData: [],
    employerView_byWorker: [],
    employerView_byJobsite: [], 
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

// Get a worker's punch in/out transactions monthly history
// This will update the workerView_monthlyPunchData field of the state
// Requires a month, workerId is obtained from the token at the backend. 
export const getWorkerViewHistory = createAsyncThunk(
    'workHistory/getWorkerViewHistory', 
    async (month, thunkAPI) => {
        try {
            const token = thunkAPI.getState().workerAuth.workerUser.webToken
            return await workHistoryService.getMonthlyPunchData_Worker(month, token)
        } catch (error) {
            const message = 
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getAttendanceByJobsite = createAsyncThunk(
    'workHistory/getJobsiteAttendance', 
    async (calendarParams, thunkAPI) => {
        try {
            const {token} = thunkAPI.getState().auth.user
            return await workHistoryService.getJobsiteAttendance(calendarParams, token)
        } catch (error) {
            const message = 
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const workHistorySlice = createSlice({
    name: 'workHistory',
    initialState,
    reducers: {
        reset: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder 
            .addCase(getWorkerViewHistory.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getWorkerViewHistory.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.workerView_monthlyPunchData = Array.isArray(action.payload.punchRecords) ? action.payload.punchRecords : []
            })
            .addCase(getWorkerViewHistory.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            }) 
            .addCase(getAttendanceByJobsite.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getAttendanceByJobsite.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.employerView_byJobsite = Array.isArray(action.payload) ? action.payload : []
            })
            .addCase(getAttendanceByJobsite.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    },
})

export const { reset } = workHistorySlice.actions
export default workHistorySlice.reducer