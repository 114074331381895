import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import getJobSites from '../actions/getJobSites';
import deleteJobsite from '../actions/deleteJobsite';
import { toggleJobsiteStatus } from '../actions/toggleJobsiteStatus';
import JobSiteCard from '../components/JobSiteCard';
import { MdOutlineDomainAdd } from "react-icons/md";
import { FaSearch } from "react-icons/fa";

const JobSites = () => {
    const { token } = useSelector(state => state.auth.user);
    const [jobSites, setJobSites] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const handleDeleteJobsite = async (jobSiteId) => {
        try {
            const response = await deleteJobsite(token, jobSiteId);
            const updatedJobSites = jobSites.filter(jobSite => jobSite.id !== jobSiteId);
            setJobSites(updatedJobSites);
        } catch (error) {
            console.error('Error deleting job site:', error);
            toast.error('Could not delete the jobsite. Please try again.');
        }
    }

    const handleToggleStatus = async (jobSiteId) => {
        try {
            const response = await toggleJobsiteStatus(token, jobSiteId);
        } catch (error) {
            console.error('Error toggling job site status:', error);
            toast.error('Could not toggle the jobsite status. Please try again.');
        }
    }

    useEffect(() => {
        const fetchJobSites = async () => {
            try {
                const jobsiteData = await getJobSites(token);
                setJobSites(jobsiteData);
            } catch (error) {
                console.error(error);
            }
        };
        fetchJobSites();
    }, [token]);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredJobSites = jobSites.filter(jobSite =>
        jobSite.jobsiteName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        jobSite.address.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <div className='container flex justify-between items-center border-b-2 p-2'>
                <div className="relative w-full max-w-md">
                    <input
                        type="text"
                        placeholder="Search job sites..."
                        value={searchQuery}
                        onChange={handleSearch}
                        className="w-full p-2 pl-10 border border-blue-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <FaSearch className="absolute left-3 top-3 text-gray-400" />
                </div>
                <div>
                    <button className='p-1 rounded-full relative group bg-green-300'>
                        <Link to='/newJobSite'>
                            <MdOutlineDomainAdd className='text-green-900 text-5xl p-1' />
                        </Link>
                        <span className="text-sm absolute top-[55px] p-1 left-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-slate-600 text-white rounded-md pointer-events-none">
                            New Job Site
                        </span>
                    </button>
                </div>
            </div>

            <div className="p-2">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                    {filteredJobSites.map((jobSite) => (
                        <div key={jobSite.id} className="p-1 m-1">
                            <JobSiteCard
                                jobSite={jobSite}
                                onDelete={handleDeleteJobsite}
                                onToggleStatus={handleToggleStatus}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default JobSites;