import React, { useEffect } from 'react';
import FacialLiveness from '../../components/FacialLiveness'; // Import the FacialLiveness component
import { useSelector } from 'react-redux'; // Import the useSelector hook from react-redux

const Punch = () => {
  //redux store
  const { jobsite, punchMachine, nextPunchType } = useSelector((state) => state.currentJobsite);
  const { latitude, longitude } = useSelector((state) => state.workerAuth.workerLocation);

  useEffect(() => {
    console.log('nextPunchType changed:', nextPunchType);
  }, [nextPunchType]);

  return (
    <>
      <div className="container">
        <h1 style={{ fontSize: '2rem', color: '#333' }}>
          {nextPunchType === 'clockIn' ? 'Clocking In' : nextPunchType === 'clockOut' ? 'Clocking Out' : ''}
        </h1>

        {!punchMachine && (
          <FacialLiveness
            jobsiteId={jobsite.id}
            workerLat={latitude}
            workerLong={longitude}
            nextPunchType={nextPunchType}
          />
        )}

     
      </div>
    </>
  );
};

export default Punch;