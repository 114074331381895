import React from 'react';

export default function Footer() {
  return (
    <footer className="bg-black text-white py-8 w-full">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0 text-center md:text-left">
            <h1 className="text-lg font-bold text-yellow-500 mb-2">TimeStat</h1>
          </div>
          <ul className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-8 text-center">
            <li>
              <a href="/privacyPolicy" className="hover:underline text-gray-300">Privacy Policy</a>
            </li>
            <li>
              <a
                className="hover:underline text-gray-300"
                href={`mailto:info@megasoftwaresolutions.com?body=From web App`}
              >
                Contact Us
              </a>
            </li>
            <li>
              <a href="/careers" className="hover:underline text-gray-300">Careers</a>
            </li>
            <li>
              <a href="/terms-of-service" className="hover:underline text-gray-300">Terms of Service</a>
            </li>
          </ul>
        </div>
        <div className="mt-6 text-center md:text-left">
          <ul className="flex justify-center md:justify-start space-x-4">
            <li>
              <a href="https://www.facebook.com/profile.php?id=100087348553796" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M22.675 0h-21.35C.596 0 0 .596 0 1.326v21.348C0 23.405.596 24 1.326 24H12.82v-9.294H9.692V11.3h3.128V8.412c0-3.1 1.892-4.786 4.656-4.786 1.325 0 2.464.099 2.795.143v3.241l-1.917.001c-1.504 0-1.795.715-1.795 1.76V11.3h3.588l-.467 3.406h-3.121V24h6.116c.73 0 1.326-.595 1.326-1.326V1.326C24 .596 23.405 0 22.675 0z"/>
                </svg>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M20.447 20.452H17.25v-5.571c0-1.328-.025-3.037-1.851-3.037-1.852 0-2.135 1.447-2.135 2.944v5.664H9.118v-11.44h3.099v1.562h.044c.431-.821 1.48-1.687 3.047-1.687 3.259 0 3.86 2.146 3.86 4.938v6.627zM5.337 8.433a1.796 1.796 0 1 1 0-3.591 1.796 1.796 0 0 1 0 3.591zM6.958 20.452H3.714V8.963h3.244v11.489zm17.042-15.452C24 2.69 23.31 2 22.453 2H1.548C.69 2 0 2.69 0 3.548v16.904C0 21.31.69 22 1.548 22h20.905c.857 0 1.547-.69 1.547-1.548V3.548z"/>
                </svg>
              </a>
            </li>
          </ul>
        </div>
        <div className="mt-6 text-center md:text-left">
          <p className="text-xs text-gray-400">&copy; 2024 TimeStat. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}