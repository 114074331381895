import OldWay from "../components/homeComponents/OldWay"
import Intro from "../components/homeComponents/Intro"
import HardwareSolution from "../components/homeComponents/HardwareSolution"
import Faq from "../components/homeComponents/Faq"
import Pricing from "../components/homeComponents/Pricing"
import Footer from "../components/homeComponents/Footer"
import FacialRecognitionSolution from "../components/homeComponents/FacialRecognitionSolution"
const Home = ()=>{
    return (
        <>    
            <Intro/>
            <OldWay/>
            <FacialRecognitionSolution/>
            <HardwareSolution/>
            <Pricing/>
            <Faq/>
            <Footer/>
        </>
    )
}

export default Home