const features = [
  { name: 'Offline Functionality', description: 'Works seamlessly without WiFi, making it ideal for remote job sites.' },
  { name: 'Durability', description: 'Rugged devices built to withstand tough environments and daily wear and tear.' },
  { name: 'Fraud Prevention', description: 'Fingerprint sensors ensure workers cannot clock in for each other, eliminating buddy punching.' },
  { name: 'Accurate Records', description: 'Keep precise attendance logs for payroll and tax compliance, even in offline conditions.' },
  { name: 'Stress-Free Payroll', description: 'Eliminate paycheck disputes with verifiable attendance data from secure hardware.' },
  { name: 'Reliable Performance', description: 'Designed for reliability, ensuring consistent operation in challenging conditions.' },
];

export default function HardwareSolution() {
  return (
      <div className="">
          <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-y-16 gap-x-8 py-24 px-4 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
              <div>
                  <h2 className="text-3xl font-mono tracking-tight sm:text-4xl">Hardware Solution</h2>
                  <p className="mt-4 text-gray-500">
                      Connectivity can be difficult to establish in a job site. Our field devices operate 
                      <span className="border-b-4 border-red-600"> without WiFi</span>, ensuring reliable attendance tracking in any environment.
                  </p>

                  <dl className="mt-16 grid grid-cols-1 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                      {features.map((feature) => (
                          <div key={feature.name} className="border-t border-gray-200 pt-4">
                              <dt>{feature.name}</dt>
                              <dd className="mt-2 text-sm text-gray-500">{feature.description}</dd>
                          </div>
                      ))}
                  </dl>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-1 gap-8">
                  {/* <iframe
                      width="100%"
                      height="360"
                      src="https://www.youtube.com/embed/0sz3XtNqDwo"
                      title="Demo Spanish"
                      className="rounded-lg"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                  ></iframe> */}

                    <iframe
                      width="100%"
                      height="360"
                      className="rounded-lg"
                      src="https://player.vimeo.com/video/850412571?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                      title="Site Demo English"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                  ></iframe>
                  <iframe
                      width="100%"
                      height="360"
                      className="rounded-lg"
                      src="https://www.youtube.com/embed/-HBbtWBeE7Q"
                      title="Demo English"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                  ></iframe>
              </div>
          </div>
      </div>
  );
}