import { useDispatch, useSelector} from 'react-redux'
import { reset} from '../features/transactions/transactionsSlice'
import BackButton from '../components/BackButton';
import Spinner from '../components/Spinner';
import TransactionItem from '../components/TransactionItem'
import {toast} from 'react-toastify'
import { useState } from 'react'
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import DateFilterButton_DateRange from '../components/DateFilterButton_DateRange';

const now = new Date();
const yesterdayBegin = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
const todayEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);

function Transactions (){
    const {transactions, isLoading , isError, message}= useSelector((state)=> state.transactions) 

    const [dateValue, onChange] = useState([yesterdayBegin, todayEnd]);
    const {workerInfo} = useSelector((state) => state.workers)
    
    const dispatch = useDispatch()

    if(isError){
        dispatch(reset())
        toast.error(message)

    }
    if(isLoading){
        return <Spinner/>
    }
    

    // Rename this file as TransactionsByWorkerId
    return(
       
        <>
            
            <BackButton url='/workers'/>
            <div>
                <div className='p-4'>
                    <h2 className='bg-yellow-200 py-2  rounded-2xl  text-lg'>  {workerInfo.name} {workerInfo.lastName}    </h2>
                   
                </div>
                
                 <div>
                     <DateRangePicker
                        onChange={onChange} 
                        value={dateValue} 
                        calendarAriaLabel="Toggle calendar"
                        clearAriaLabel="Clear value"
                        dayAriaLabel="Day"
                        monthAriaLabel="Month"
                        nativeInputAriaLabel="Date"
                        yearAriaLabel="Year"
                     
                     />
                    
                   
                    <DateFilterButton_DateRange dateObj={dateValue}  />
                 </div>
                 
                 
            </div>
            <div>
                <div className='tickets mt-4'>
                    <div className='ticket-headings'>

                        <div>Location & Date</div>
                        <div>Time In </div>
                        <div>Time Out</div>
                        <div>Hours</div>    
                        </div>
                        
                        {transactions.map((transaction) =>(
                        <TransactionItem key={transaction.timeIN} transaction={transaction} />
                    ))}
                    </div>
            </div>
           
            
        </>
    )
}


 export default Transactions