import { useState, useEffect } from 'react';
import React from 'react';

const AddressStep = ({ setAddress, nextStep }) => {
    const [childAddy, setChildAddy] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [state, setState] = useState('');
    const [error, setError] = useState('');
    const [isAddressSet, setIsAddressSet] = useState(false);

    //monitor the state of the address
    
    useEffect(() => {
        if (isAddressSet) {
            nextStep();
        }
    }, [isAddressSet, nextStep]);

    const handleChangeAddress = (event) => {
        setChildAddy(event.target.value);
    };

    const handleSetZipcode = (event) => {
        setZipcode(event.target.value);
    };

    const handleSetState = (event) => {
        setState(event.target.value);
    };

    const workerAddressData = {
        address: childAddy,
        zipcode: zipcode,
        state: state
    };

    const states = ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado",
        "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana",
        "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan",
        "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire",
        "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma",
        "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee",
        "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin",
        "Wyoming"];

    const stateOptions = states.map((state) => (
        <option key={state} value={state}>
            {state}
        </option>
    ));

    
    const handleFinishClick = () => {
        if (!childAddy || !zipcode || !state) {
            setError('All fields are required.');
            return;
        }
        setError('');
        setAddress(workerAddressData);
        setIsAddressSet(true);
    };

    return (
        <div className='flex flex-col'>
            <div className='mb-2'>
                <label
                    className='block text-sm font-medium text-gray-700'
                    htmlFor="address">Address:</label>
                <input
                    type="text"
                    id="address"
                    value={childAddy}
                    onChange={handleChangeAddress}
                    className='border border-black rounded p-1 ml-1'
                    placeholder='123 Main St APT 3B'
                />
            </div>

            <div className='mb-2'>
                <label
                    className='block text-sm font-medium text-gray-700'
                    htmlFor="zipcode">Zip Code:</label>
                <input
                    type="text"
                    id="zipcode"
                    value={zipcode}
                    onChange={handleSetZipcode}
                    maxLength={5}
                    className='border border-black rounded p-1 ml-1'
                />
            </div>

            <div>
                <label
                    className='block text-sm font-medium text-gray-700'
                    htmlFor="state">State:</label>

                <select
                    id="state"
                    value={state}
                    onChange={handleSetState}
                    className='border rounded p-1 ml-1'
                    size={state ? 1 : 4}
                >
                    <option value="">Select a state</option>
                    {stateOptions}
                </select>
            </div>

            {error && <p className='text-red-500'>{error}</p>}

            <div className='mt-2'>
                <button onClick={handleFinishClick}
                    className='bg-indigo-700 text-white p-2 rounded-lg'>
                    Finish
                </button>
            </div>
        </div>
    );
};

export default AddressStep;