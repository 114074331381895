
import { useDispatch } from 'react-redux'
import { transactionsByWorkerDateRange } from '../features/transactions/transactionsSlice'


const DateFilterButton_DateRange = ({dateObj}) => {
   
    const dispatch = useDispatch()

    // transform to iso format 
    function toUtcIso(date){
        const fecha =  new Date(date)
        
        const month = fecha.getMonth()
        const dia = fecha.getDate()
        const year = fecha.getFullYear()        
        
        const utcDate =  new Date(Date.UTC(year,month,dia)) 
        
        return (utcDate.toISOString())
    }

    return(

        
        <button className="bg-green-300 hover:bg-green-600 rounded-md text-lg p-1 ml-2" 
        
            onClick={ ()=>{
            
                // let dateStr = dateInput.toISOString()
                const dateA= new Date(dateObj[0])
                const dateB= new Date(dateObj[1])
        

                const dateOne = toUtcIso(dateA)
                const dateTwo = toUtcIso(dateB)
                // 
                dispatch(transactionsByWorkerDateRange({dateOne,dateTwo}))
            }}
        
        >
                     
             Search
           
           
        </button>
    )
}

export default DateFilterButton_DateRange