import { useNavigate } from 'react-router-dom';
// import { BuildingOffice2Icon, UserIcon } from '@heroicons/react/24/outline'; // Example: Using Heroicons

 export default function Intro() {
   const navigate = useNavigate();

   const handleBusinessLogin = () => {
     navigate('/login');
   };

   const handleWorkerLogin = () => {
     navigate('/workerLogin');
   };

   return (
     // Added a subtle gradient background and ensured text is readable
     <div className="relative flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-50 to-gray-200 text-gray-800">
       {/* Optional: Add a container for max-width on very large screens */}
       <div className="text-center px-6 py-12 max-w-4xl mx-auto">
         {/* Increased contrast/weight for the main headline */}
         
         {/* Adjusted sub-headline slightly */}
         <h1 className="text-3xl sm:text-4xl md:text-5xl font-normal text-charcoal mt-2">
           Time keeping - but <span className="text-amber-600 font-medium">fraud free</span>
         </h1>
         <h1 className="text-4xl sm:text-5xl md:text-6xl font-sans font-bold text-charcoal mb-2">
           For Construction.
         </h1>
         {/* Increased paragraph spacing and line height for readability */}
         <p className="text-lg sm:text-xl text-gray-600 mt-6 max-w-2xl mx-auto leading-relaxed">
           Simplify attendance tracking across multiple jobsites with effortless tracking—no extra hardware needed.
         </p>
         {/* Increased spacing before buttons, adjusted button styles */}
         <div className="mt-10 flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-6">
           {/* Primary Button: Business Login */}
           <button
             className="flex items-center justify-center bg-amber-500 text-black font-semibold px-8 py-3 rounded-lg shadow-md hover:bg-amber-600 transition duration-300 ease-in-out transform hover:scale-105 w-full sm:w-auto"
             onClick={handleBusinessLogin}
           >
            
             Business Login
           </button>
           {/* Secondary Button: Worker Login (Outline style) */}
           <button
             className="flex items-center justify-center border border-amber-500 text-amber-600 font-semibold px-8 py-3 rounded-lg shadow-sm hover:bg-amber-500 hover:text-black transition duration-300 ease-in-out transform hover:scale-105 w-full sm:w-auto"
             onClick={handleWorkerLogin}
           >
            
             Worker Login
           </button>
         </div>
       </div>
     </div>
   );
 }