//This page displays all the workers belonging to a user 
import {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {getWorkers , reset} from  '../features/workers/workerSlice'
import Spinner from '../components/Spinner'
import WorkerItem from '../components/WorkerItem'
import {FaUserPlus} from 'react-icons/fa'
import { Link } from 'react-router-dom';

function Workers(){

    const {workers, isLoading, isSuccess}= useSelector((state)=> state.workers) 
    const dispatch = useDispatch();
  


    const [searchTerm, setSearchTerm] = useState("");

    const filterWorkers = (searchTerm) => {
        return Array.isArray(workers) ? workers.filter((worker) =>
            worker.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            worker.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            worker.phone.includes(searchTerm)
        ) : [];
    };


    /**
     * fix: calling Services twice
     */
    useEffect(() =>{
        return () =>{
            if(isSuccess){
                dispatch(reset())
            }
        }
    },[dispatch,isSuccess])

    //get all workers
    useEffect(()=>{
        dispatch(getWorkers())
    },[dispatch])



    if(isLoading){
        return <Spinner/>
    }

    return (
        <>
          <div className='container flex justify-end'>
                {/* <BackButton url='/homedash' />
                     */}
                    {/* not sure why, byt reload documents works. Look into it.  skip client side routing and let the browser handle the transition normally (as if it were an <a href>). */}
                    <Link  to="/new-worker" reloadDocument className="bg-green-300 inline-flex items-center px-2 py-2 font-sans rounded-md">
                        <FaUserPlus className='mr-2 text-green-900'/>
                    New Worker
                    </Link>


            </div>

           
       
          
            {/* Search Bar */}
            <div className='container mx-auto'>
                <div >
                     <input
                        type="text"
                        placeholder="Name or phone"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="mb-2 px-2 py-1 rounded  bg-slate-200 placeholder-gray-600 
                            transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
                    />
                </div>
            </div>
            
            <div>
                <div className='container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4'>
                    {filterWorkers(searchTerm).map((worker) => (
                    <WorkerItem key={worker.id} worker={worker} />
                    ))}
            </div>
</div>
               
            
            
        </>
    )
}
export default Workers