//this page renders if a worker is not onboarded yet. 
// meaning the worker still has a temporary password and must complete onboarding. 
//Or a worker is coming from a new company and needs to enroll his face. 

import {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Spinner from '../../components/Spinner' 
import {useNavigate} from 'react-router-dom'

// import {Link} from 'react-router-dom'

import PasswordStep from '../../components/workerOnboarding/PasswordStep'
import SsnStep from '../../components/workerOnboarding/SsnStep'
import AddressStep from '../../components/workerOnboarding/AdddressStep'
import ProfilePictureStep from '../../components/workerOnboarding/ProfilePictureStep'
import { toast } from 'react-toastify'
import {onboardWorker} from '../../features/workerAuth/workerAuthSlice'


function WorkerOnboarding (){
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // Redux states
    const { workerUser, isError, isSuccess, isLoading, message } = useSelector(state => state.workerAuth);
    const { onboarded } = workerUser;
   
    useEffect(() => {
        if (onboarded) {
            toast.success(message);
            navigate('/workerDash');
        }

        if (isError) {
            toast.error('Failed to complete onboarding, try again.');
        }
    }, [onboarded, isError, message, navigate]);


    const [step, setStep] = useState(1);
    const [password, setPassword] = useState("");
    const [ssn, setSsn] = useState("");
    const [address, setAddress] = useState({});
    const [sessionId, setSessionId] = useState("");

    


    /**
     * Change state variable functions to be passed to child components
     * State info is then sent to the backend
     */
    const handleSessionIdChange = (sessionId) => {
       setSessionId(sessionId)
    };

    const handleSetPassword = (password) => {
        setPassword(password)
    };

    const handleSetSSN = (ssn) => {
        setSsn(ssn)
       
    };

    const handleSetAddress = (childAddress) => {
        setAddress(childAddress)
    };

    /**
     * Updates Worker's details
     * Send data to the backend for processing
     *
     */

    //Submit the data to the backend. 
    const submitData = () => {
        const onboardData = {
            liveness_Session: sessionId,
            taxID: ssn,
            address: address,
            password: password
        };

        dispatch(onboardWorker(onboardData))
    };

    const nextStep = () => {
        if (step < 4) {
          setStep(step + 1);
        }
    };


    if(isLoading){
        return <Spinner/>
    }
    
    return(
    <>
        {/* From Redux, get worker name*/}
        <div>
            <h1 className='text-xl font-bold text-center mt-5'> {workerUser.workerName} {workerUser.workerLastName}</h1>
            <p className='text-xl font-normal text-center mb-5'> {step}/4</p>

        </div>

        {
        /* Step 1, verify real person and get worker picture, and sessionID */
        /* Step 2, set password ONLY IF worker is new */
        /* Step 3, Enter SSN o ITIN, may be skipped */
        /* Step 4, Enter mailing address. */
        }
        
        <div>
            {step === 1 &&  
            <ProfilePictureStep nextStep={nextStep} onSessionIdChange={handleSessionIdChange} />}

            {step === 2 && 
            <PasswordStep setPassword={handleSetPassword} nextStep={nextStep} />} 

            {step === 3 && 
            <SsnStep setSsn={handleSetSSN} nextStep={nextStep} />} 

            {step === 4 && 
            <AddressStep setAddress={handleSetAddress} nextStep={submitData} />} 
        </div>
    </>
    )
}

export default WorkerOnboarding;