// This is the card that will display the jobsite information. It will display the jobsite name, address, notes, and a few icons that will allow the user to interact with the jobsite.
import { useState } from "react";
import { FaCog, FaUserClock, FaVideo, FaStickyNote } from "react-icons/fa";

import NotesInCards from "./NotesInCards";

import {useNavigate} from "react-router-dom";

//the onDelete and onToggleStatus are functions that are passed down from the parent component(JobSites.jsx)
const JobSiteCard = ({ jobSite, onDelete , onToggleStatus }) => {
    const navigate = useNavigate();
    const [showDropdown, setShowDropdown] = useState(false);
    const [editNotesClicked, setEditNotesClicked]= useState(false);


    //toggle the edit notes button
    const handleEditNotes = () => { 
        setEditNotesClicked(!editNotesClicked);
       
    }

    const handleCogClick = () => {
     
      setShowDropdown(!showDropdown); 

    }

    // navigate to route /clockIn/:userID/:jobsiteID
    //use the token of the user to access this route. 
    const handleClockIn = () => {
      console.log("The clock in button was pressed. Navigate to the clockin Page")
      
      //check if user can flip the camera on the device. 
    }


   

   

  
    //show a modal which will display the attendance of the workers at the given jobsite.
    const handleViewAttendance = () => {
      console.log("The attendance button was pressed");
      navigate('/jobsite/attendance/', {
          state: { jobsiteID: jobSite.id, jobsiteName: jobSite.jobsiteName }
      });
    } ;

    //deletes the jobsite from the database, ask the user if they are sure they want to delete the jobsite.
    const handleDeleteJobsite =  () => { 
      //calls the parent component's onDelete function which will delete the jobsite from the database.
      onDelete(jobSite.id);
    }

    const handleShowBuilding = () => { 
     
      console.log(jobSite.id)
    }

    const handleSelectForeman = () => {
      console.log("The assign supervisor button was pressed");
    }

    return (
    <>
      <div className="mb-10 rounded-md border border-gray-200 w-full sm:w-80 max-w-md mx-auto  bg-gradient-to-b from-black from-10% via-slate-600 via-40% to-slate-100 to-70%">
        
        {/* button to toggle the jonsite status to true or false */}
        <div className="flex justify-between">
          {/* display the status as an h1 */}
          <div>
            <h1
              className={`ml-2 mb-0 mt-1 text-yellow-400 bg-black rounded font-mono font-bold px-2 py-1`}
            >
              {jobSite.jobsiteName.toUpperCase()}
            </h1>

          </div>
        

          <div className="relative">
            
            <div>
              <button
                  className="mt-2 mr-2 transform hover:scale-150"
                  onClick={handleCogClick}
                > 

                  <FaCog className="text-yellow-400 text-2xl"/>
                </button>

            </div>
            
            {/* COG Dropdown  */}

              {showDropdown && (
                <div className="absolute right-0 mt-2 z-10"> 
                  <div className="w-48 rounded shadow-lg ring-1 ring-black ring-opacity-5">

                      <div className="py-1 bg-white rounded-lg" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">

                          

                         
                          <div className="rounded  hover:bg-slate-100"> 
                            <button
                              className="block px-4 text-md text-black"
                              role="menuitem"
                              onClick= {handleSelectForeman}
                            
                            >
                              Assign Foreman
                  
                            </button>
                          </div>

                          

      
                          <div className=" rounded hover:bg-red-100"> 
                            <button
                              className="block px-4 text-red-600 font-semibold"
                              role="menuitem"
                              onClick={handleDeleteJobsite}
                            > Delete Jobsite
                            </button>
                          </div>
                        
                      </div>


                  </div>
                  
                </div>
              )}

          </div>
        
        </div>
          {/* image of jobsite */}
            <div className="relative p-2" >
              <img src={jobSite.s3URL ? jobSite.s3URL : "/constructionSite.png"} alt="Job Site" className="w-full h-64 object-cover rounded-md cursor-pointer" />

            </div>

        
            {/*  Address and foreman*/}
            <div className="mb-2">
              <h1 
                className="text-sm hover:underline cursor-pointer mb-1 text-right mr-2 font-sans font-semibold" 
                onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(jobSite.address.split(', USA')[0])}`, "_blank")}>
                {jobSite.address.split(', USA')[0]}
              </h1>
              
            </div>

            {/* notes section */}
          <div className="relative mb-2">
            
            {editNotesClicked ? (
              <NotesInCards jobSite={jobSite} setEditNotesClicked={setEditNotesClicked} />
              ) : (
              <div className="text-sm rounded-lg p-0.5 border-b-4 overflow-y-auto max-h-32 h-32 cursor-default " >
                {jobSite.siteNotes ? (
                  Object.entries(jobSite.siteNotes).map(([key, value]) => (
                  <p key={key} className="text-gray-800 font-thin text-left">
                      <span className="mr-1 font-normal"> {`${key}:`} </span>{`${value}`}</p>
                  ))
              ) : (
                <div className="flex items-center justify-center h-full">
                  <p className="text-gray-800 font-thin ">No Notes- you can add one!</p>
                </div>
                )}
            </div>
            )}
        </div>

          {/* Row of icons */}
        
          {!editNotesClicked && (
         

            <div className='flex justify-between  mb-4 px-10'>
              {/* Clock in a worker */}
              <button className='relative group' onClick={handleClockIn}>
                    <FaVideo className="text-2xl  cursor-pointer" />
                    <span className="absolute top-[-40px] p-0.5 right-6  flex items-center justify-center
                          opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-gray-600
                           text-white text-xs rounded-md  font-thin">
                      Take Attendance
                    </span>
              </button>

              {/* Show jobsite attendace button */}
              <button className='relative group' onClick={handleViewAttendance}>
                  <FaUserClock className="text-2xl  cursor-pointer" />
                  <span className="absolute top-[-50px] p-0.5  flex items-center justify-center 
                      opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-gray-600
                      text-white text-xs rounded-md font-thin">
                      View Attendance
                  </span>
            </button>

              {/* Notes Button */}
              <button className="relative group" onClick={handleEditNotes}>
                <FaStickyNote className="text-2xl cursor-pointer" />
                  <span className="absolute top-[-40px] p-0.5 left-6  flex items-center justify-center 
                      opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-gray-600
                      text-white text-xs rounded-md  font-thin">
                      Post a note.
                    </span>
              </button>
            </div>
          )}


          {/* Modals */}
          


          </div>
    </>

    );
  }
  
  export default JobSiteCard;
